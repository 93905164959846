import axios from "axios"

const createOpening = async (openingData) => {
    try {
        const response = await axios.post("/api/openings", openingData);
        return response.data.opening;
    }
    catch (e) {
        console.log("failed creating interview");
        console.log(e)
    }
}

const fetchOpenings = async () => {
    try {
        const response = await axios.get("/api/openings");
        if (response.status == 200) {
            return response.data.openings;
        }
        else {
            // should display something went wrong
        }
    }
    catch (e) {
        console.log("failed fetching openings");
        console.log(e)
    }
}

const fetchOpeningById = async (openingId) => {
    try {
        if (openingId) {
            const response = await axios.get(`/api/openings/opening/${openingId}`);
            return response.data.opening;
        }
        else {
            throw new Error("Invalid ID")
        }
    }
    catch (e) {
        console.log("failed fetching opening by Id", openingId);
        console.log(e)
    }
}

const updateOpeningById = async (openingId, updatedData) => {
    try {
        if (openingId) {
            const response = await axios.put(`/api/openings/${openingId}`, updatedData);
            // need to make better validation and error displays in the UI based on response schema
            return response.data;
        }
        else {
            throw new Error("Invalid ID")
        }

    }
    catch (e) {
        console.log("failed updating opening by Id", openingId);
        console.log(e)
    }

}

const deleteOpeningById = async (openingId) => {
    try {
        if (openingId) {
            const response = await axios.delete(`/api/openings/${openingId}`);
            return response.data;
        }
        else {
            throw new Error("Invalid ID")
        }

    }
    catch (e) {
        console.log("failed deleting opening by Id", openingId);
        console.log(e)
    }
}

export { createOpening, fetchOpenings, fetchOpeningById, deleteOpeningById, updateOpeningById }