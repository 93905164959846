import * as React from 'react';
import VoiceCreate from './VoiceCreate';
import Grid from '@mui/material/Grid';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NotFound from './components/NotFound';
import ChatGpt from './ChatGpt';
import AdminPanel from './pages/AdminPanel';
import Interview from './pages/Interview';
import BaseAdminNavPage from './pages/BaseAdminNavPage';
import CreateOpening from './pages/CreateOpening';
import InterviewEnd from './pages/InterviewEnd';
import OpeningEvaluations from './pages/OpeningEvaluations';
import InterviewReport from './pages/InterviewReport';
import Home from './pages/Home';
import InterviewHomePage from './pages/InterviewHomePage';
import UserDetails from './pages/UserDetails';
import InterviewMessages from './pages/InterviewMessages';
import InterviewTopicOptions from "./pages/InterviewTopicOptions"
import EvaluationAndMessages from './pages/EvaluationAndMessages';
import { CssBaseline } from '@mui/material';
const defaultTheme = createTheme();


export default function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/end' element={<InterviewEnd />} />
          <Route path='/interview/:openingId' element={<InterviewHomePage />} >
            <Route index element={<UserDetails />} />
            <Route path='topics/:interviewReportId' element={<InterviewTopicOptions />} />
            <Route path='start/:interviewReportId' element={<Interview />} />
            {/* <Route path='userDetails' element={<UserDetails />} /> */}
            {/* <Route path='demo' element={<Interview />} /> */}
          </Route>
          <Route path='riktam' element={<BaseAdminNavPage />} >
            <Route path='admin' element={<AdminPanel />} />
            <Route path='evaluations/:openingId' element={<OpeningEvaluations />} />
            <Route path='evaluation/:interviewReportId' element={<InterviewReport />} />
            <Route path='messages/:interviewReportId' element={<InterviewMessages />} />
            {/* <Route index element={<InterviewReport />} /> */}
            {/* </Route> */}
            <Route path='create-opening' element={<CreateOpening />} />
            <Route path='create-opening/:openingId' element={<CreateOpening />} />
          </Route>
          {/* <Route path='/:id' element={<VoiceCreate />} /> */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}