import { Typography, Box, Button } from '@mui/material'
import React from 'react'

const InterviewEnd = () => {
    return (
        <Box style={{ minHeight: "100vh", backgroundColor: "#f2f2f2" }} display={'flex'} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
            <Typography variant='h4' component={"h1"}>Your Interview has Ended</Typography>
            <Typography variant='h6' component={"div"} gutterBottom>Thank you For Participating {":)"}</Typography>
        </Box>
    )
}

export default InterviewEnd