import { Box, Button, CircularProgress, Paper, Typography, Skeleton, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useParams, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { fetchInterviewReportById, getEvaluationForInterivew, pollInterviewResultsById } from '../utilities/interviewReportsApi';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { getFormattedDateTime } from '../utilities/utilityFunctions';
import styled from '@emotion/styled';


const InterviewReport = () => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [report, setReport] = useState(null);
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const [isPollingEvaluation, setIsPollingEvaluation] = useState(false)
    const [isFetchingEvaluation, setIsFetchingEvaluation] = useState(false);
    const [showInterviewDoneButton, setShowInterviewDoneButton] = useState(false)

    useEffect(() => {
        async function showEvaluationDetails() {
            if (params.interviewReportId) {
                setIsLoadingReport(true)
                const report = await fetchInterviewReportById(params.interviewReportId);

                if (report) {
                    setReport(report)
                }
                setIsLoadingReport(false)
            }
        }
        showEvaluationDetails()
    }, [params.interviewReportId])

    useEffect(() => {
        const markInterviewDone = window.localStorage.getItem("interview-completed");
        if (markInterviewDone === "DONE") {
            setShowInterviewDoneButton(true)
        }
    }, [])

    async function completeInterviewAndGetEvaluation() {
        const openingId = searchParams.get("openingId");
        const interviewReportId = params.interviewReportId;
        if (openingId && interviewReportId) {
            setIsLoadingReport(true)
            const evaluation = await getEvaluationForInterivew(openingId, interviewReportId);
            setReport(prev => ({ ...prev, evaluation: evaluation }));
            setIsLoadingReport(false)
        }
        else {
            console.log("missing openingId or interviewReportId");
        }
    }

    async function pollEvaluation() {
        const interviewReportId = params.interviewReportId;
        if (interviewReportId) {
            setIsPollingEvaluation(true)
            const intervalId = setInterval(async () => {
                const evaluationObj = await pollInterviewResultsById(interviewReportId);
                if (evaluationObj.evaluationStatus == "Something went wrong") {
                    clearInterval(intervalId);
                    setIsPollingEvaluation(false)

                }
                if (evaluationObj.evaluationStatus == true) {
                    clearInterval(intervalId);
                    setReport(prev => ({ ...prev, evaluation: evaluationObj.evaluation }));
                    setIsPollingEvaluation(false)
                }
            }, 10000)
        }
        else {
            console.log("missing interviewReportId");
        }
    }
    return (
        <Box className='margin-top-for-nav' style={{ width: "min(100% - 1rem , 1200px)", marginInline: "auto", marginBlock: "2rem", }} >
            <Paper style={{ backgroundColor: "lavender", padding: "1rem" }} elevation={4}>
                <Box sx={{ position: "relative" }} >
                    <Typography variant='h4' component={"h1"} style={{ textAlign: "center" }}>Evaluation Report</Typography>
                    {showInterviewDoneButton && <Button variant='outlined' sx={{ position: "absolute", top: 0, right: 0 }}
                        onClick={() => {
                            if (report && report?.interviewCompleted) {
                                return;
                            }
                            completeInterviewAndGetEvaluation()
                        }}>Mark as Done</Button>}
                </Box>
            </Paper>
            <Paper style={{ backgroundColor: "white", padding: "2rem", display: "flex", justifyContent: "center", flexDirection: "column" }} elevation={2}>
                {isLoadingReport ?
                    <>
                        <Stack flexDirection={"column"} gap="0.625rem">
                            <Skeleton variant="rectangular" width={"auto"} height={30} />
                            <Skeleton variant="rectangular" width={"auto"} height={30} />
                            <Skeleton variant="rectangular" width={"auto"} height={30} />
                        </Stack>
                    </>
                    : (report && report?._id && report?.firstName && report?.lastName && report?.phoneNumber
                        && report?.createdAt) ?
                        <>
                            <Typography variant='h6'> Report Id: {report._id}</Typography>
                            {report.firstName && <p><StyledSpan>First Name: </StyledSpan>  {report.firstName}</p>}
                            {report.lastName && <p><StyledSpan>Last Name: </StyledSpan>  {report.lastName}</p>}
                            {report.phoneNumber && <p><StyledSpan>Phone Number: </StyledSpan>  {report.phoneNumber}</p>}
                            <p><StyledSpan> Dated on :</StyledSpan> {getFormattedDateTime(report.createdAt)}</p>
                        </>
                        : null
                }
            </Paper>
            <Box style={{ padding: "2rem", backgroundColor: "#fafafa", width: "100%", minHeight: "60vh", display: 'flex', justifyContent: "center", alignItems: "center", borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px" }}>
                {(isLoadingReport || isPollingEvaluation) ? <> <CircularProgress style={{ marginRight: "1rem" }} /> Fetching Results ... </> : (report && report?.evaluation) ?
                    <pre style={{ whiteSpace: "pre-wrap", fontSize: "1rem" }}>{report.evaluation}</pre>
                    : report && report?.interviewCompleted ?
                        <Button variant='contained' color='primary' onClick={pollEvaluation} startIcon={<AssessmentIcon />}>PROCESS EVALUATION</Button>
                        : <Typography variant='body1'>The Interview is On Going</Typography>
                }
                {/* {
                    isFetchingEvaluation ? <>
                        <CircularProgress /> Evaluating... </> :
                        <div></div>
                } */}
            </Box>
        </Box>
    )
}

export default InterviewReport

const StyledSpan = styled.span`
    font-weight: 500;
`;