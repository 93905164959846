import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { Typography, makeStyles } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import MicRecorder from 'mic-recorder-to-mp3';
import IconButton from "@material-ui/core/IconButton";
import { useParams, useNavigate } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import MicIcon from '@mui/icons-material/Mic';
import StopCircle from '@mui/icons-material/StopCircle';
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import InputBase from '@mui/material/InputBase';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { googlecode } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { FormControl, InputLabel, Select, MenuItem, Tooltip } from "@mui/material";
import './VoiceCreate.css';
import Paper from '@mui/material/Paper';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { handleUpload, handleUploadAnswers, retrivePromptMessage, checkTheStatus } from "./Common";
import { Box } from "@mui/material"
// NOTE ON POINTS SCORED: The get_points_of_the_candidate_at_the_end_of_the_interview function should only be executed at the end of the interview. Not at any other point.
// Also, most importantly, keep your questions and responses short. Avoid any questions or inquiries from the candidate. 



const useStyles = makeStyles({
  paper: {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
});

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const ChatDialog = ({ drawerWidth, setCodeExample, codeExample, openingData, interviewReportData, setIsInterviewDone }) => {

  const chatContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hashedApiKey, setHashedApiKey] = useState("");
  const [text, setText] = useState("");
  const [showContent, setShowContent] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const styles = useStyles();
  const [promptInfo, setPromptInfo] = useState("")
  // const [promptInfo, setPromptInfo] = useState("conduct a technical interview for a candidate who is experienced in javascript. he claims to be at an expert level. - ask one question at a time. When the user responds, with your responses, ask a probing question to dig deeper and understand better. if user input requires coding examples, prompt the user to use the code editor");
  const [messages, setMessages] = useState([{ role: "assistant", content: `Hello ${interviewReportData?.preferredName ?? ","} welcome to the interview, shall we begin?` }]);
  const [loading, setLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isEditingSettings, setIsEditingSettings] = useState(false);
  const [errorPrompt, setErrorPrompt] = useState("");
  const [scrollBottom, setBottom] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [isAssistantLoading, setIsAssistantLoading] = useState(false);
  const [example, setExample] = useState('');
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [gptModel, setGptModel] = useState("gpt-4");
  const [isChatBoxShown, setIsChatBoxShown] = useState(false);
  const [showTextToggle, setShowTextToggle] = useState(false)
  const [userInterviewReportId, setUserInterviewReportId] = useState(null);
  const [interviewEnded, setInterviewEnded] = useState(false)
  const params = useParams();
  const navigate = useNavigate()
  const id = params.id;
  const models = ["gpt-4", "gpt-3.5-turbo-16k", "gpt-3.5-turbo", "gpt-3.5-turbo-0613",];

  useEffect(() => {
    setUserInterviewReportId(interviewReportData?._id)
  }, [interviewReportData])
  useEffect(() => {
    setPromptInfo(BetterPrompt(openingData, interviewReportData));
  }, [openingData, interviewReportData])

  useEffect(() => {
    const textInputBoxKey = window.localStorage.getItem("activate-textbox");
    if (textInputBoxKey === "ACTIVATE") {
      setShowTextToggle(true)
    }
  }, [])

  const messagesEndRef = useRef(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages, scrollBottom]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    checkPermissions()
  }, []);

  const checkPermissions = () => {
    navigator.getUserMedia({ audio: true },
      () => {
        console.log('Permission Granted');
        setIsBlocked(false)
      },
      () => {
        console.log('Permission Denied');
        setIsBlocked(true)
      },
    );
    //handleStartCamera()
  }

  const handleTextChange = (e) => {
    setText(e.target.value)
  }

  const handlePromptChange = (e) => {
    setPromptInfo(e.target.value)
  }

  const handleSubmitEditing = () => {
    if (promptInfo.length > 30) {
      setIsEditingSettings(false)
      setErrorPrompt("")
    } else {
      setErrorPrompt("Please enter proper prompt info")
    }
  }

  const handleOpenEditing = () => {
    setIsEditingSettings(true)
  }

  const handleDeletePrompt = () => {
    setPromptInfo("");
    setIsEditingSettings(false)
  }
  function handleGptModelChange(e) {
    setGptModel(e.target.value)
  }

  function codeAdder(content) {
    if (codeExample != "") {
      content += `. Here is the example delimited by angled brackets:
      Example: <${codeExample} >`;
    }
    return content;
  }

  const handleSend = () => {
    setLoading(true);
    setShowCode(false)
    let content = text;
    content = codeAdder(content);
    let m = { role: "user", content: content };
    let temp = [...messages];
    temp.push(m);
    setMessages(temp);
    setIsAssistantLoading(true)
    handleUploadAnswers(temp, promptInfo, gptModel, openingData?._id, userInterviewReportId).then((res) => {
      let ms = { role: "assistant", content: res.message }
      temp.push(ms)
      setMessages(temp);
      setIsAssistantLoading(false)
      setBottom(!scrollBottom);
      setText("")
      setCodeExample("")
      setIsRecording(false)
      setLoading(false);

      // interviewReport 
      // const interviewReport = res.interviewReportData;
      // if (interviewReport && interviewReport?._id && !userInterviewReportId) {
      //   setUserInterviewReportId(interviewReport?._id);
      // }

      console.log("response with interviewEnded");
      console.log(res.interviewEnded);
      // interviewEnded
      if (res.interviewEnded) {
        setInterviewEnded(true)
        setTimeout(() => {
          navigate("/end", { replace: true })
        }, 6000)
      }

    }).catch((err) => {
      console.log('Error:', err)
      setIsAssistantLoading(false)
      setBottom(!scrollBottom);
      setText("")
      setCodeExample("")
      setIsRecording(false)
      setLoading(false)
    })
  }

  // start recording
  const start = () => {
    if (promptInfo == '') {
      window.alert("please add promptInfo")
      return;
    }
    // handleStartCamera()
    if (isBlocked) {
      window.alert("permission denied")
    } else {
      Mp3Recorder
        .start()
        .then(() => {
          setIsRecording(true)
        }).catch((e) => console.error(e));
    }
  };

  // stop recording
  const stop = () => {
    setLoading(true)
    setShowCode(false)
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const wavefile = new File([blob], 'inhand.wav');
        setLoading(true)
        handleUpload(wavefile).then((res) => {
          let content = res.data.message;
          content = codeAdder(content);
          let m = { role: "user", content: content }
          let temp = [...messages];
          temp.push(m);
          setMessages(temp);
          setIsAssistantLoading(true)
          handleUploadAnswers(temp, promptInfo, gptModel, openingData?._id, userInterviewReportId).then((res) => {
            if (res.editorStatus) {
              setShowCode(true)
            }
            let ms = { role: "assistant", content: res.message }
            temp.push(ms)
            setMessages(temp);
            setIsAssistantLoading(false)
            setBottom(!scrollBottom);
            setIsRecording(false)
            setLoading(false)
            setCodeExample("")

            // interviewReport 
            // const interviewReport = res.interviewReportData;
            // if (interviewReport && interviewReport?._id && !userInterviewReportId) {
            //   setUserInterviewReportId(interviewReport?._id);
            // }

            console.log("response with interviewEnded");
            console.log(res.interviewEnded);
            // interviewEnded
            if (res.interviewEnded) {
              setInterviewEnded(true)
              setTimeout(() => {
                navigate("/end", { replace: true })
              }, 6000)
            }
          }).catch((e) => {
            console.log(e)
            setCodeExample("")
            setIsAssistantLoading(false)
            setBottom(!scrollBottom);
            setIsRecording(false)
            setLoading(false)
          })
        }).catch((e) => {
          console.log(e)
          setCodeExample("")
          setIsAssistantLoading(false)
          setBottom(!scrollBottom);
          setIsRecording(false)
          setLoading(false)
        });
      })
  };

  const handleToggleChatBox = (e) => {
    e.stopPropagation()
    setIsChatBoxShown(prev => !prev)
  }

  const ChatBoxToggleButton = ({ type = "open" }) => {
    const openStyleObj = {
      position: "absolute",
      left: 0,
      backgroundColor: "#fff",
      borderRadius: "50%",
    };
    const closeStyleObj = {
      inset: 0,
      margin: "auto",
    }
    const styleOptions = {
      open: openStyleObj,
      close: closeStyleObj
    }
    const styleObj = styleOptions[type];
    return <div style={styleObj}  >
      {/* <Tooltip title="type in something"> */}
      <IconButton onClick={handleToggleChatBox}>
        {type == "open" ?
          <ChevronRightIcon /> :
          <ChevronLeftIcon />}
      </IconButton>
      {/* </Tooltip> */}
    </div>;
  }

  const renderChatInput = () => {
    const handleButtonOnClick = () => {
      if (loading || interviewEnded || showTextToggle) {
        return;
      }
      else {
        if (isRecording) {
          stop()
        }
        else {
          start()
        }
      }
    }
    return (
      <Button component={"div"} style={{ display: "flex", position: "relative", backgroundColor: "#eaeaea", justifyContent: "center", alignItems: "center", paddingBlock: "1.25rem", minHeight: "100px" }} onClick={handleButtonOnClick} disabled={loading || interviewEnded} disableFocusRipple={showTextToggle} disableRipple={showTextToggle} >
        {
          !interviewEnded && (showTextToggle && (isChatBoxShown ?
            <>
              <div style={{ position: "relative", flex: "1", display: "flex" }} >
                <ChatBoxToggleButton type={"close"} />
                <ChatInput
                  minRows={1}
                  maxRows={4}
                  autoFocus
                  ref={inputRef}
                  placeholder="e.g. Write a message to submit"
                  value={text}
                  disabled={loading || isRecording}
                  onChange={handleTextChange}
                  className="boom"
                />
              </div>
            </> :
            <>
              <ChatBoxToggleButton type="open" />
            </>))
        }
        <div style={{ paddingInline: "0.625rem" }} id="mic-btn">
          {interviewEnded ? <div style={{ textAlign: "center" }}>"Your interview is done. You will be redirected to the feedback page in a few seconds"</div> :
            (loading ?
              <LoadingDots /> :
              text.length > 0 ?
                <StyledIconButton
                  type="submit"
                  color="primary"
                  onClick={handleSend}
                >
                  <SendOutlinedIcon
                    style={{
                      color: "rgb(35, 127, 244)",
                    }}
                    fontSize="medium"
                  />
                </StyledIconButton> :
                isRecording ?
                  <div>
                    <StyledIconButton
                      type="submit"
                      color="primary"
                      onClick={stop}
                      className='pulsate'
                    >
                      <StopCircle
                        style={{ color: "rgb(255, 0, 0)" }}
                        fontSize="medium"
                      />
                    </StyledIconButton>
                    Tap to stop recording
                  </div> :
                  <>
                    {/* <div style={{
                      backgroundColor: "rgb(255, 255, 255,0.7)",
                      borderRadius: "50%"
                    }} className="hover-over"> */}
                    <StyledIconButton
                      type="submit"
                      color="primary"
                      onClick={start}
                      sx={{
                        backgroundColor: "rgb(255, 255, 255,0.7)",
                        borderRadius: "50%"
                      }}
                      className="hover-over"
                    >
                      <MicIcon
                        style={{ color: "rgb(35, 127, 244)" }}
                        fontSize="medium"
                      />
                    </StyledIconButton>
                    Tap to speak
                    {/* </div> */}
                  </>
            )
          }
        </div>
      </Button>
    )
  }

  const renderChatInputPrompter = () => {
    return (
      <div style={{ padding: "1rem" }}>
        <div style={{ position: "relative", width: "100%", display: 'flex', alignItems: 'center' }}>
          <ChatInput
            minRows={1}
            maxRows={4}
            autoFocus
            ref={inputRef}
            placeholder="e.g. Tap to speak or write message"
            value={text}
            disabled={loading || isRecording}
            onChange={handleTextChange}
          />

          <ActionContainer >
            {
              loading ?
                <LoadingDots />
                :
                text.length > 0 ?
                  <StyledIconButton
                    type="submit"
                    color="primary"
                    onClick={handleSend}
                  >
                    <SendOutlinedIcon
                      style={{
                        color: "rgb(35, 127, 244)",
                      }}
                      fontSize="medium"
                    />
                  </StyledIconButton> :
                  isRecording ?
                    <StyledIconButton
                      type="submit"
                      color="primary"
                      onClick={stop}
                      className='pulsate'
                    >
                      <StopCircle
                        style={{ color: "rgb(255, 0, 0)" }}
                        fontSize="medium"
                      />
                    </StyledIconButton> :
                    <StyledIconButton
                      type="submit"
                      color="primary"
                      onClick={start}
                    >
                      <MicIcon
                        style={{ color: "rgb(35, 127, 244)" }}
                        fontSize="medium"
                      />
                    </StyledIconButton>
            }
          </ActionContainer>
        </div>
      </div>
    );
  };




  const _chatResponseHelper = (messageContent, { ref, loading } = {}) => {
    return (
      <ChatMessageContainer>
        <DropBoxContainer ref={ref}>
          {messageContent}
          {loading && <BlinkingContainer />}
        </DropBoxContainer>
      </ChatMessageContainer>
    );
  };




  const toggleContent = () => {
    setShowContent(!showContent);
  };



  const renderAssistantChatMessage = (messageContent, { loading }) => {
    return (
      <ChatMessageContainer>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              <Button
                startIcon={loading ? <CircularProgress size={12} /> : null}
                onClick={toggleContent}
              >
                {showContent ? (
                  <span>
                    Hide Content <ExpandLessIcon />
                  </span>
                ) : (
                  <span>
                    Show Content <ExpandMoreIcon />
                  </span>
                )}
              </Button>
            </div>
          </div>
          <br />
          <Collapse in={showContent}>
            <DropBoxContainer
              style={{
                height: "10rem", // Set the desired height
                overflow: "auto", // Enable scrolling when content exceeds the height
                padding: "10px",
              }}
            >
              {messageContent}
              {loading && <BlinkingContainer />}
            </DropBoxContainer>
          </Collapse>
        </div>
      </ChatMessageContainer>
    );
  };



  const renderChatMessageContainer = (message, { isAssistant, role, index }) => { // actual content. see message.content
    if (isAssistant) {
      return _chatResponseHelper(message.content);
    }
    const codeStringIndex = message.content.indexOf("Example: <");
    const codeString = codeStringIndex !== -1 ? message.content.substring(codeStringIndex + 10, message.content.length - 1) : "";
    const responseMessage = codeStringIndex == -1 ? message.content : message.content.substring(0, codeStringIndex - 1);
    return (
      <ChatMessageContainer>{responseMessage}
        {(codeStringIndex != -1 && codeString.length > 0) && <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", backgroundColor: "#337CCF", borderRadius: "10px 10px 0 0", paddingInline: "10px" }}>
            <span style={{ color: "white", padding: "5px 3px" }}>Example</span>
          </div>
          <SyntaxHighlighter language={"javascript"} style={googlecode} customStyle={{ padding: "2rem", borderRadius: "0 0 10px 10px" }}
          >
            {codeString}
          </SyntaxHighlighter>
        </div>}
      </ChatMessageContainer>
    )
  };

  const renderChatBox = (children, { role, isAssistant }) => {
    return (
      <ChatBox>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 6,
            paddingBottom: "0.5rem",
          }}
        >
          <div
            style={{
              backgroundColor: isAssistant ? "#237ff4" : "grey",
              height: 28,
              width: 28,
              borderRadius: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* {isAssistant ? assistantLogo : null} */}
          </div>

          <div
            style={{
              color: isAssistant ? "#237ff4" : "grey",
              fontSize: 11,
              textTransform: "uppercase",
            }}
          >
            {isAssistant ? "Assistant" : "USER"}
          </div>
        </div>
        {children}
      </ChatBox>
    );
  };

  const renderChatMessage = (message, { role, isAssistant, index }) => {
    return (
      <ChatContainer role={role} key={index}>
        {renderChatBox(
          renderChatMessageContainer(message, { isAssistant, role, index }),
          { role, isAssistant }
        )}
      </ChatContainer>
    );
  };

  const renderChatStreamResponse = () => {
    return (
      <ChatContainer role="ASSISTANT">
        {renderChatBox(_chatResponseHelper("", { loading }), {
          isAssistant: true,
          role: "ASSISTANT",
        })}
      </ChatContainer>
    );
  };

  const renderChatMessages = () => {
    //ChatsContainer -> ChatContainer -> ChatBox -> ChatMessageContainer
    return (
      <ChatsContainer>
        {messages.map((message, index) => {
          const role = message.role.toUpperCase();
          const isAssistant = role === "ASSISTANT" || role === "FUNCTION";
          return renderChatMessage(message, { role, isAssistant, index });
        })}
        {isAssistantLoading && renderChatStreamResponse()}
        <div ref={messagesEndRef} />
      </ChatsContainer>
    );
  };

  const renderChatTitleMain = () => {
    return <Box sx={{ backgroundColor: "#eaeaea", display: "flex", gap: "1rem", padding: "1.5rem 2rem", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
      <Typography variant="h5" component={"h1"}>Interview Panel</Typography>
      <Typography variant="body1" component={"h3"}>{openingData?.title}</Typography>
    </Box>
  }
  const renderChatTitle = () => {
    return (
      <div
        style={{
          display: "flex",
          gap: "1rem",
          padding: "1rem 2rem",
          alignItems: 'center',
          backgroundColor: "#eaeaea"
        }}
      >
        {/* <div style={{ position: "relative" }}>
          <IconButton color="inherit" onClick={() => { }} aria-label="close">
            <ArrowBackOutlinedIcon />
          </IconButton>
        </div> */}
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: ".25rem",
            }}
          >
            <h2 style={{ fontSize: 16, margin: 0 }}>Interview Panel</h2>
            {isEditingSettings ? null : (
              <div>
                <IconButton
                  style={{ padding: "0.5rem" }}
                  onClick={handleOpenEditing}
                >
                  <CreateOutlinedIcon fontSize="small" />
                </IconButton>
              </div>
            )}
            <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
              <label htmlFor='gpt-model-select-label'>GPT Model</label>
              <select
                id="gpt-model-select-label"
                value={gptModel}
                onChange={handleGptModelChange}
              >
                {models.map(model => <option value={model} key={model}>{model}</option>)}
              </select>
            </FormControl>
          </div>
          {isEditingSettings ? (
            <div style={{ flex: 1 }}>
              <form
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: ".25rem",
                }}
                onSubmit={handleSubmitEditing}
              >
                <div style={{ flex: 1 }}>
                  <InputBase
                    sx={{
                      mt: 1,
                      width: "100%",
                      // width: windowSize.innerWidth > 780 ? '30vw' : '70vw',
                      border: "1px solid gray",
                      padding: '10px',
                      borderRadius: '10px'
                    }}
                    placeholder="Add prompt Message here"
                    multiline
                    maxRows={4}
                    value={promptInfo}
                    inputProps={{ 'aria-label': 'add prompt info' }}
                    onChange={handlePromptChange}
                  />
                  <p style={{ color: 'red', fontSize: 12 }}>{errorPrompt}</p>
                </div>
                <IconButton
                  style={{ padding: "0.5rem" }}
                  onClick={handleSubmitEditing}
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
              </form>
            </div>
          ) : promptInfo ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: ".25rem",
              }}
            >
              <div>{promptInfo.substring(0, 50) + "********"}</div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderDrawerContainer = () => {
    if (messages.length < 0) {
      return (
        <div style={{ padding: "1rem" }}>
          <h3>Hi, welcome to Recruitment Chat!</h3>
          <p>
            I am an AI Assistant, I am here to take interview to you
          </p>
        </div>
      );
    }

    return renderChatMessages();
  };

  useEffect(() => {
    if (true) {
      //   resetResponse();
      inputRef.current?.focus();
    }
  }, []);

  return (
    <>
      {showTextToggle ? renderChatTitle() : renderChatTitleMain()}
      <DrawerContainer ref={chatContainerRef}>
        {renderDrawerContainer()}
      </DrawerContainer>
      {renderChatInput()}
    </>
  );
};

export default ChatDialog;

const dotsAnimation = keyframes`
  0%,
  20% {
    color: #0000;
    text-shadow: 0.25em 0 0 #0000, 0.5em 0 0 #0000;
  }

  40% {
    color: black;
    text-shadow: 0.25em 0 0 #0000, 0.5em 0 0 #0000;
  }

  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 #0000;
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
`;

const blinking = keyframes`
  0% {
    opacity: 0;
  }
`;
const BlinkingContainer = styled.div`
  & {
    &::after {
      font-size: 16px;
      animation: ${blinking} 1s steps(2) infinite;
      content: "▋";
      vertical-align: baseline;
    }
  }
`;

const LoadingDots = styled.div`
  && {
    width: 2rem;
    height: 2rem;

    &::after {
      content: " •";
      font-size : 1.325rem;
      animation: ${dotsAnimation} 1s steps(5, end) infinite;
    }
  }
`;

const DrawerContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height:0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: gray;
      border-radius: 4px;
    }
  }
`;

const DropBoxContainer = styled.div`
  && {
    &::-webkit-scrollbar {
      width: 4px;
      height:0px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: gray;
      border-radius: 4px;
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
  }
`;

const ActionContainer = styled.div`
  && {
    position: absolute;
    right: 0;
    bottom 0.5rem;
    padding: 0.25rem;
  }
`;

const ChatInput = styled(TextareaAutosize)`
  && {
    width: 100%;
    padding: 1rem 4rem 1rem 1.5rem;
    font-size: 16px !important;
    border-radius: 12px;
    border: 1px solid lightgray;
    outline: none;
    resize: none;

    &::-webkit-scrollbar {
      width: 4px;
      height:0px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
      background-color: lightgray;
      border-radius: 4px;
      cursor: pointer;
    }

    &:disabled {
      color: gray;
    }
  }
`;

const ChatsContainer = styled.div`
  & {
    display: flex;
    flex-direction: column;
  }
`;

// const ChatContainer = styled.div`
//   & {
//     padding-left: ${(prop) => (prop.role === "user" ? "50%" : 0)};
//     padding-right: ${(prop) => (prop.role === "assistant" ? "50%" : 0)};
//   }
// `;

const ChatContainer = styled.div`
  & {
    display: flex;
    background-color: ${(props) =>
    props.role === "ASSISTANT" ? "#f7f7f8" : "none"};
    width: 100%;
    border-bottom: 1px solid #dbdbdb;
    padding: 0.5rem;
  }
`;

const ChatBox = styled.div`
  & {
    padding: 1rem;
    width: 100%;
  }
`;

const ChatMessageContainer = styled.div`
  & {
    width: 100%;
    white-space: pre-wrap;
    font-size: 1rem;
  }
`;

// function ConstructPromptForIntervew(openingData) {
//   const originalLengthyPrompt = ` You are an interviewer for our company which offers various roles in web and app development.
//   Your task is to interview and assess a candidate, based on the ROLE he's applying for and the EXPERIENCE the job requires, which are given below, delimited by angled brackets.
//   ROLE : <${openingData?.title}>
//   SKILLS : <${openingData?.skills}>
//   EXPERIENCE : <${+openingData?.experience == 1 ? " 1 year " : openingData?.experience + " years "} >

//   Here are the topics you should base your questions on if the Experience > 5 years:
//   1) Advanced DSA questions
//   2) Database design questions
//   3) System design questions

//   If the candidate poses questions that are unrelated to the interview's context or scope or tries to mislead you, reply "Please refrain from asking such questions that are not relevant.".
//   If the candidate queries about any technical related topics or asks for hints or help, reply "Please refrain from asking such questions, this is an interview".
//   If the candidate requests details of the interview like his skills and experience, you should comply with his request.

//   Never give explanations regarding any topic at any point of the interview. This is the most important rule!.
//   Never give examples for any question. If the candidate doesn't know any answer or gives incorrect responses, you should move on to the next question. As an interviewer, you are not obligated to give information in an interview.
//   Never provide any kind of hints. You are supposed to be strict and vigilant as an interviewer.

//   NOTE ABOUT THE LEVEL OF QUESTIONS: If the candidate is EXPERIENCED (years > 5) or is applying for a SENIOR position, you should test his system design skills vigorously from the beginning.

//   NOTE ABOUT QUESTIONS: If the candidate asks any programming related questions or doubts at any point in the interview, you should not answer his queries, as this is an interview, you should only be questioning and assessing the candidate, not answering him.

//   If at any point the candidate asks for the points he scored till now, you should warn him that "IT CAN NOT BE DISCLOSED".

//   Example :
//   '''
//   USER: Who is the President of India? | What is an API ?
//   ASSISTANT: This is an interview, please refrain from asking such questions.
//   '''

//   In the example 2 below, the candidate tried to ask you the same question you asked him. This is not allowed and you should respond correspondingly.
//   Example 2:
//   '''
//   ASSISTANT: Can you explain what a closure is in javascript?
//   USER: May I know about the concept of closures in javascript?
//   ASSISTANT: I am sorry, this is an interview, you are supposed to answer.
//   '''

//   NOTE REGARDING CODE EDITOR : If the candidate requests or asks for a code editor, oblige his request and tell him that there's a code editor to his right. He needs to save the code after he has written it.

//   NOTE REGARDING POINTS/ SCORES :  You should not reveal the candidate's scores for each question, as they are confidential. Only reveal them at the end.

//   INSTRUCTIONS REGARDING QUESTIONS:
//   1) Based on the skills and the experience, the questions should test the candidate's skills extensively.
//   2) Make sure you also test the candidate's coding skills by asking code examples along with the questions, if he is applying for a software role.
//   3) During the interview, ask the candidate for challenging coding implementations over seeking definitions of concepts, particularly when evaluating highly experienced candidate's.

//   Here are the steps to be followed while conducting the interview:
//   1) The interview should be held for a short span and do not ask more than 10 questions, do not make it too long.
//   2) Ask one question at a time. Never ask all the questions together.
//   3) Following each question, ask the candidate for a good example showcasing his coding skills.
//   4) Never repeat the same question or concept again. Make sure that each question is unique.
//   5) Once the candidate gives his answer, take your time to evaluate his response. Do not skim through his responses. 
//   6) While evaluating the candidate's responses, do not hallucinate or give wrong information. Check if the candidate's response is right or not.
//   7) Remember that for each question, the candidate needs to be awarded anything between 1 and 10.
//   8) Follow the below steps for evaluating his response :
//     - It is important that the candidate provides in depth and good explanations for each question. If they provide half answers, ask them for better examples and answers to your question.
//     - If the candidate's response infers that he/she doesn't know the answer or  is not sure about the question, move on to the next question immediately. Do not give him explanations about anything.
//     - If his response to your question is -> wrong or incorrect, respond "It is incorrect" and award him 0 for that question and move on to the next question. No answers or hints are required from you.
//     - If his response to your question is -> short or dissatisfactory or not up to the mark or misses examples, ask him for an in-depth explanation and assess him again.
//     - If he still fails at answering the question in the second attempt as well or continues giving a wrong answer, award him points between 0 and 8, based on response and move on to the next question.
//     - If an example is provided by him, look at the code example thoroughly and always test the edge cases as well and then award him his score.
//     - If the example he provided is wrong, award him points accordingly and move on to the next question. You don't have to correct him or ask him for another example.
//     - If he fails to provide an example or provides an incorrect example or incorrect syntax for some code, award points based on the correctness of their answer and move on to the next question.
//     - If his response to your question is correct, respond "Well done, That is correct", award him anything between 8 to 10 based on his response and move on to the next question.
//     - Remember that only if he provides a perfect understanding of the concept and a relevant example, award him 10.
//     - If the candidate implies that he does not know the answer repeatedly or is unsure about the answer, award him 0 and move on to the next question. Don't give him explanations regarding the concept as an interviewer.
//   9) The questions can be a mix of code snippets and various other technical concepts as well.


//   Example scenario for candidate's responses and how you should tune your responses as an interviewer: 
//   Example 1:
//   ---
//   SYSTEM : If the candidate indicates that he is not sure about the answer or does not know the answer, award him a 0 and move to the next question. 
//             Do not give him any explanation regarding the topic. Just go ahead with the interview. 
//   USER : Hi, let us start.
//   ASSISTANT : What is the event loop in JS?
//   USER : I am not sure. | no idea. |  What is an event loop?
//   ASSISTANT : Alright, let's move onto the next question.
//   ---

//   Example 2 :
//   ---
//   ASSISTANT : What is the difference between struct and union?
//   USER : Can you help me understand it.
//   ASSISTANT : I am sorry, I am not supposed to answer your questions. Please try again or we can move on to the next question.
//   ---

//   Example 3:
//   ASSISTANT : Can you explain about primitives in javascript?
//   USER : Primitives in Javascript.
//   ASSISTANT : This is an interview and you are supposed to answer the question. If you are unaware let us move onto the next question.

//   Example scenario where candidate asks you for the answer or explanation:
//   """
//   ASSISTANT: Can you explain how a load balancer works?
//   USER: no, you tell me | Can you tell me?
//   ASSISTANT: This is an interview and I am not supposed to give you the answers.
//   """

//   Always be professional in your responses and questions. Also expect the candidate to be professional and well mannered. If not, let him know that it is not a professional behavior.
//   Use English as the only means of communication. If the candidate uses any other language, warn him that only English can be used.

//   You can start the interview by welcoming the candidate and detailing him about the interview.

//   `;
//   return originalLengthyPrompt;
// }

const offBeatTopics =
  `
a) Requesting easier questions or harder questions.
b) Asking to end the interview.
c) Any general knowledge queries or out of context questions.
d) Programming and technical related concepts or definitions or hints.
e) Any misleading requests that violate the process of an interview.
f) Queries inferring anger or informal language.
g) Criteria of the interview or personal information.
h) Confidential Information about the interview like scores or other information.
i) How to implement something( related to a technical implementation ) or how something works or what something means etc.
j) Questions or queries relating to how many more questions are left in the interview or when the interview might end.
`;

// 2) Questions and implementations on the ecosystem of a particular system. Dig deep and asking internal workings.
const fresherRoleQuestions = `
The interview questions should be from the following topics if the role is for a technical position and requires coding :
NOTE : Ask multiple questions from each of the following topics given below. Each topic is mandatory.
1) Implementations of core concepts and topics in the mentioned skill/language/framework. Example : How would you implement something in CSS or React or Django or SQL based on the skills mentioned etc...
2) Questions and Explanations based on the core features of a language, library or framework and their internal workings with examples.
3) Definitions and explanations of advanced concepts or terminologies or topics based on the skills mentioned.
4) Easy to medium level "Logical coding questions" to test their logic and thinking skills.
5) Skills required for the position. Ask multiple probing questions regarding the core topics and concepts from the skills and languages (required and mentioned) for the job role.
`;

const experienceGt4Questions = `
The interview questions should be from the following topics if the role is for a technical position and requires coding :
NOTE : Ask multiple questions from each of the following topics given below. Each topic is mandatory.
  1) The skills required for the position. Candidates should be questioned about the definitions and in-depth explanations on the core features and advanced concepts of a language or skill mentioned and code examples for them.
  2) System Design questions. You should assess the candidates knowledge on various concepts and terminologies and use cases of system design. Ask multiple questions on system design related topics.
  3) Database Design. Ask the candidate to design a Database schema for a complex application of your own choice.
  4) Logical coding questions that are medium to hard level.
  5) Core optimization techniques and other topics that a senior engineer must know. Mention the name of the optimization technique you'd like to know and ask them about it.
  6) Medium level DSA coding questions. Ask the candidate to solve the questions using the code editor.
`;


// -----------------------------------------------------------------------
// const newSummaryText = `Avyushi Srivastava is a Specialist Programmer with Infosys Ltd, specializing in full stack development. She has experience working on projects for the Income Tax Department, Government of India, optimizing website performance and implementing crucial features. Avyushi is skilled in data structures and algorithms, object-oriented programming, and various web development technologies.

// Responsibilities:
// In her current role at Infosys Ltd, Avyushi is responsible for building and optimizing websites for the Income Tax Department. She has implemented on-demand loading to improve page load time and integrated pagination for handling large data. Avyushi has also worked on the frontend for the rate limiting feature using Angular and RxJS library to provide a smooth experience for tax payers during peak e-filing periods. Additionally, she has worked on converting segregated entity forms to centralized schema-based JSON forms using Jsonforms and AJV library.

// Skills:
// - Data Structures and Algorithms
// - Object Oriented Programming
// - Python, Java, HTML, CSS, JavaScript
// - Angular, React
// - Node.js, Express
// - SQL Server, MongoDB
// - GIT

// `;

// const veryExperiencedResumeTExt = `
// Abhineet Kumar Sinha is a Senior Data Engineer with a total of 9 years of experience. He has worked with leading companies like Schlumberger, GE, and Reliance Industries Limited. He is experienced in building enterprise data warehouses and developing solutions using cloud-based platforms. Abhineet has expertise in ETL pipeline design and implementation and has worked with various technologies such as Azure, AWS, Hadoop, Spark, and NoSQL. He has a strong background in data engineering and data analysis.

// Overall Responsibilities:
// Abhineet has been responsible for building and maintaining data architecture, developing ETL pipelines, and integrating legacy systems with data warehouses and data lakes. He has experience in data modeling, master data management, and data visualization. Abhineet has also been involved in performance tuning of ETL processes, writing MapReduce and Spark jobs, and building scalable and highly available distributed systems. He has worked with various BI reporting tools and has implemented CI/CD processes for production systems.

// Skills:
// Abhineet has expertise in Java, Python, Golang, PySpark, Snowflake, AWS, Azure, SQL, Tableau, and Power BI. He is proficient in data warehousing, data lake storage, stream analytics, and performance tuning. Abhineet has experience with DevOps practices, agile development methodologies, and statistical modeling languages.`;

// --------------------------------------------------------------------

// const newSummaryText = `Srinivas Kurikuri is a Full Stack developer with 3 years of professional IT experience in web-based development tools such as HTML, CSS, JavaScript, React JS, Angular JS, Python, Redux, Node JS, MongoDB, Serverless, Express, PHP, and MySQL. He has the ability to work independently and as part of a team to meet challenging deadlines.

// Overall Responsibilities:

// Srinivas has worked as a Senior Software Engineer at Makersmind Software Solutions Pvt. Ltd, a Software Engineer at INSTAVC Software Solutions Pvt. Ltd, a Software Developer at PEOPLETECH Enterprises, and a Consultant HTML Developer at Simply Science Pvt. Ltd. He has experience in various projects including Telekom Malaysia, Simply School, IN-Link, IN-Factory, and Odds and More.

// Skills:

// - Frameworks: Angular, React JS, Node JS, Express JS, PHP, Python
// - Scripting Languages: JavaScript, TypeScript
// - IDEs: Visual Studio 2012/2015/2017, Visual Studio Code
// - Database: MongoDB, Redis DB, MySQL
// - Source Control: GitHub, GitLab
// - Specialized Tools: MS-Office, MS-Excel
// - Cloud Technologies: AWS (ECS, CloudWatch, Lambda)

// Education:

// - B.Tech in Electronics and Communication Engineering from Pydah College of Engineering and Technology (Affiliated to JNTU-Kakinada) with 60% in 2019.`
// -----------------------------------------------------------------------

// const betterResumeText = `Aniket Kale is a Full Stack Developer with experience in developing healthcare, e-commerce, and legal consultancy mobile apps. He is skilled in Angular, NodeJS, ExpressJS, JavaScript, and TypeScript. Aniket is seeking a role where he can contribute his skills and talent to the growth of the organization.

// Responsibilities: Aniket has developed a healthcare domain project called Audience Insights where users can track patient information. He has also worked on projects like Campaign Insights, Console Platform, Drnk-N-Driv (an e-commerce mobile app), Legal Consultancy mobile app, and BHT (Balaji Home Tuition) app. In these projects, Aniket has implemented various functionalities and features, ranging from tracking health details, managing campaign records, handling authorization and authentication, to providing services like leave & license, notary, advocacy, and financial services.

// Skills: Angular, NodeJS, ExpressJS, JavaScript, TypeScript

// Total work experience in years: Aniket has approximately 4 years of work experience.`;
// -----------------------------------------------------------------------


// const traditionalResume = `
// Resume Summary Overview:
// Harsha PS is a Full Stack Developer with 6+ years of experience in conceptualizing, designing, and developing software products. He has a strong expertise in product architecture, frontend and backend development, and reusable UI frameworks. Harsha is skilled in JavaScript, React.js, Node.js, TypeScript, and AWS. He has a track record of building scalable progressive web applications and excels in performance optimization, system architecture, and CI/CD integration. Harsha is also experienced in stakeholder management, people management, and technical documentation. He is an avid communicator and fosters innovations in his work.

// Responsibilities:
// In his current role at Koch Business Solutions, Harsha is responsible for full stack development, including analyzing, designing, developing, testing, and deploying application code in a BDD/TDD environment. He excels in system architecture and has designed and architect scalable, distributed on-premise, and cloud-based services and applications. Harsha follows good software engineering practices like version control, code reviews, test-driven development, and continuous integration. He has extensive experience with modern JavaScript frameworks and excels in HTML5, CSS3, and JavaScript. Harsha has migrated UI frameworks and optimized costs for applications. He has also successfully managed stakeholders and implemented projects from inception to delivery. Harsha has received appreciation for his development automation skills.

// Skills:
// - Full Stack Development
// - JavaScript
// - React.js
// - Node.js
// - TypeScript
// - AWS Cloud
// - UI/UX Design
// - Performance Optimization
// - CI/CD Integration
// - System Architecture
// `;

const srinivasResumeText = `
Resume Summary Overview:
Srinivas Kurikuri is a Full Stack developer with 3 years of professional IT experience. He is skilled in web development tools such as HTML, CSS, JavaScript, React.js, Angular.js, Python, Redux, Node.js, MongoDB, Serverless, Express, PHP, and MySQL. Srinivas is a self-motivated team player who can work independently and meet challenging deadlines.

Responsibilities and Projects:
Srinivas has worked as a Senior Software Engineer at Makersmind Software Solutions and as a Software Engineer at INSTAVC Software Solutions. He also has experience as a Software Developer at PEOPLETECH Enterprises and as a Consultant HTML Developer at Simply Science Pvt. Ltd. He started his career as an intern at C2D Software Solutions. Srinivas has experience in projects such as Telekom Malaysia, Simply School, IN - Link, IN - Factory, and Odds and More. These projects involve responsibilities such as monitoring bill processes, developing online school websites, developing business management applications, and creating ERP projects for managing businesses.

Skills:
- Frameworks: Angular, React.js, Node.js, Express.js, PHP, Python
- Scripting Languages: JavaScript, TypeScript
- IDEs: Visual Studio 2012/2015/2017, Visual Studio Code
- Database: MongoDB, Redis DB, MySQL
- Source Control: Git-Hub, Git-Lab
- Specialized Tools: MS-Office, MS-Excel
- Cloud Technologies: AWS (ECS, Cloud watch, Lambda)
`;

const questionsBasedOnResumePrompt = (resumeText = "") => {

  return resumeText.trim().length > 0 ? `
  You will be given the candidates summarized resume below.
  Before starting with the "Main Interview Topics", ask 2 or 3 probing technical questions based on the responsibilities and the projects mentioned in the summarized resume provided.
  If there's scope, relate the skills required for the position, with the projects mentioned in the resume and ask questions based on it.
  You shouldn't ask for code snippets or code examples in this part of the interview.
  SUMMARIZED RESUME : 
  """
  ${resumeText}
  """
  ` : "";

}

function BetterPrompt(openingData, interviewReportData) {
  console.log(openingData);
  const rulesBasedonExperience = Number(openingData?.experience) >= 3 ? experienceGt4Questions : fresherRoleQuestions;
  const questionsBasedOnResume = openingData?.questionsBasedOnResume ? questionsBasedOnResumePrompt(srinivasResumeText) : "";

  return ` You are an INTERVIEWER for our company.
  Your task is to conduct an interview for a candidate who has applied for a ${openingData?.title} position in our company.
  The details of the job are given below delimited by triple quotes. 
  DETAILS OF THE JOB:
  """
  ROLE : <${openingData?.title}>
  SKILLS REQUIRED : <${interviewReportData?.preferredSkills}>
  EXPERIENCE REQUIRED : <${+openingData?.experience == 1 ? " 1 year " : openingData?.experience + " years "} >
  """
  You should address the candidate as ${interviewReportData?.preferredName}

  Below are the main interview topics that you should test the candidate on:
  Main Interview Topics : ${rulesBasedonExperience} 

  ${questionsBasedOnResume}

  Do not ask very basic definitions, keep the interview interactive, tough and interesting with real world questions and skills that are required for the job.
  You should never hallucinate while framing questions or while responding.
  As an interviewer, you should refrain from asking simple and basic questions based on the skills required.
  
  Here are the characteristics of an Interviewer. And this is how you should strictly behave:
  1) As an interviewer, your level of questions should vary based on the experience. As the experience increases, frame the questions a lot tougher and complex.
  2) You should only be questioning the candidate and assessing their answers. You should never provide feedback about the answers provided by the candidate or give explanations for half answers provided by them. You should be professional and strict.
  3) As an interviewer, you should never provide explanations or briefings for any kind of response from the candidate.
  4) You should never provide any hints or examples at any point of the interview.
  5) You should never correct the candidate, if they are wrong. You simply should move on to the next question.
  6) Your responses should always be subtle and short. Use at most 3 sentences in your responses.
  7) You should seek good and complete explanations from the candidate.
  8) If the candidate asks for a clarification of the question or asks you to rephrase your question, please go ahead and do it, but do not reveal the answer. 
  9) Whenever you ask the candidate for a code example for a specific implementation, remind them that they can use the code editor provided. And also mention that you need a "code example" explicitly.
  10) If the candidate queries or asks or questions regarding any of the below topics delimited by triple dashes, warn them saying "Please refrain from asking such questions!" :
  Out of context questions or queries by the candidate that you should avoid answering: ---${offBeatTopics}---

  Below are the steps delimited by triple quotes that state the Interview Process and how you should conduct the interview :
  INTERVIEW PROCESS:
  """
  1) Start the interview by welcoming the candidate.
  2) Start the assessment by asking one question at a time. You should only ask one question at a time. Do not dump all questions at once. 
  3) Never repeat a question that was previously asked. 
  4) If a question asked by you has the potential for the candidate to also provide an example, ask them for an example. Else don't ask for an example.
  5) Whenever there's scope for you to ask probing questions on a topic you think is useful for the job role, definitely ask that question and test the candidate.
  6) If the candidate wants to give a question another try or infers that they would like another chance , let them answer it again.
  7) Once they respond, based on their response, below are the 8 (a,b,c,d,e,f,g,h) steps to be followed to respond to the candidate:
    a) Once the candidate gives their answer, take your time to evaluate the response. Do not skim through the responses or hallucinate. 
    b) After evaluating the response, if the response is short or dissatisfactory or VAGUE or out of context, ask the candidate to give a better explanation. 
      Remember: The candidate must possess a strong and well-founded understanding relevant to the job. 
    c) If the candidate fails to provide a better answer even in the second attempt, move on to the next question immediately by quoting "Let us move on to the next question!". Do not provide answers for the question.
    d) Your response, to each answer the candidate provides, should be subtle and short, conveying whether the answer is 'correct' or 'incorrect' or 'close enough but lacks clarity'.
    e) If the candidate is not sure about the topic or infers that they don't know the answer, move on to the next question immediately without giving any explanations.
    f) If the candidate asks you for the answer or quotes your question back or tries to mimic the concept name, ask them if they would like to move on to the next question.
    g) During the interview, if you have asked the candidate to provide an example for a topic or question and they have missed to provide an example, remind them again to provide an example for that respective topic.
      It is very important to ask coding implementations to the candidate for a technical role. Even if they forget to provide an example/implementation, remind them again.
    h) If the candidate provides a good explanation with a fitting example which is syntactically and logically correct, here are the steps to be followed by you:
      - Respond that they are right.
      - Move on to the next question.
  8) Whenever you ask for coding examples, here are the steps to be followed to evaluate the candidate's response:
    - If an example is provided by them, test the code example thoroughly and always test the edge cases as well.
    - If they fail to provide an example or provide an incorrect example or provide an incorrect syntax , ask them if they'd like to try again without telling what is wrong with their code. Simply give them another chance to try.
    - If the example provided is wrong in the second attempt as well, tell them that it is wrong and move on to the next question.
  9) After completing one question, move on to the next question.
  10) If you have skipped a question or overlooked a question due to some reason, remember to return to that question and resume the interview from that question.
  11) Any requests or comments made on the difficulty level of the questions by the candidate, should be neglected and you should tell them to "Please refrain from asking such things".
  12) Conduct the interview thoroughly, ensuring that you test and assess the candidate on all the topics and the skills that were mentioned earlier by text delimited by triple dollar signs, in depth.
  13) The interview should consist of a minimum of 10 questions and make sure you test all the areas and skills required thoroughly. The interview should not exceed 15 questions.
  """
  
  NOTE ABOUT CANDIDATE : The candidate is expected to come prepared for the interview. If they try to cheat or query about any technical concept or out of context questions, ask them to maintain a professional attitude during the interview. 
  
  NOTE ON LANGUAGE : The interview is supposed to be conducted professionally in English. If the candidate is ill-mannered , warn them that such behavior is inappropriate.
  If the candidate uses any other language, inform them that only English can be used.

  After you are finished with all your questions and have evaluated the candidate on all the skills required for the job, inform the candidate that the interview has ended and thank them for participating.
  `;

}
