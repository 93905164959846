import { Typography, Box, Button, } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getEvaluation } from "./../Common"
const Home = () => {
    const navigate = useNavigate()
    async function handleGetEvaluation() {
        await getEvaluation()
    }
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <Box style={{ minHeight: "100vh", backgroundColor: "#f2f2f2" }} display={'flex'} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
            <Typography variant='h4' component={"h1"}>Welcome to Recruit Pro</Typography>
            <Typography variant='h6' component={"div"}>Head on to the Admin Panel</Typography>
            {/* <Button variant='text' color='secondary' size='large' onClick={() => { navigate("/riktam/admin") }}>ADMIN</Button> */}
        </Box>
    )
}

export default Home