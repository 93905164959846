import React, { useState, useRef, useEffect } from 'react'
import { Box, Stack, TextField, InputLabel, FormControl, MenuItem, Select, IconButton, Chip, Paper, Tooltip } from '@mui/material'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from "@mui/icons-material/Delete"
import HelpIcon from '@mui/icons-material/Help';

const SkillGroup = ({ onChange, handleDeleteSkillGroup, data }) => {
    const [editModeForGroupName, setEditModeForGroupName] = useState(false);
    const [skillsGroup, setSkillsGroup] = useState({ skillGroupName: "Skill Group", skills: [], criteria: 1 });
    const [singleSkill, setSingleSkill] = useState("");
    const skillsBoxRef = useRef();
    function handleEditGroupName(e) {
        e.preventDefault()
        setEditModeForGroupName(true)
    }
    function handleSaveGroupName(e) {
        e.preventDefault();
        setEditModeForGroupName(false)
        skillsBoxRef?.current?.focus()
    }
    const handleAddNewSkill = (e) => {
        if (e.key == "Enter" || e.key === ",") {
            e.preventDefault();
            const { value } = e.target;
            if (value.trim() !== "") {
                setSkillsGroup(prev => {
                    return { ...prev, skills: [...prev.skills, value] };
                })
                setSingleSkill("")
            }
        }
    }
    const handleDelete = (index) => {
        setSkillsGroup(prev => {
            let prevSkills = [...prev.skills];
            prevSkills = prevSkills.filter((skill, i) => i !== index);
            return { ...prev, skills: prevSkills }
        })
    }
    useEffect(() => {
        onChange(skillsGroup)
    }, [skillsGroup])

    function handleGroupDetailsChange(e) {
        const { name, value } = e.target;

        setSkillsGroup(prev => {
            if (name === "skills" && value.trim() != "") {
                return { ...prev, skills: [...prev.skills, value] }
            }
            if (name === "skillGroupName") {
                return { ...prev, skillGroupName: value }
            }
            if (name === "criteria") {
                return { ...prev, criteria: value }
            }
        })
    }

    return (
        <Paper sx={{ bgcolor: "#fff", padding: "2rem" }} elevation={3}>
            <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={"1rem"}>
                    {editModeForGroupName ?
                        <TextField
                            variant='filled'
                            name='skillGroupName'
                            autoFocus={true}
                            value={skillsGroup.skillGroupName || ""}
                            onChange={handleGroupDetailsChange}
                            label="Skill Group Name"
                            onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                    e.preventDefault()
                                    handleSaveGroupName(e)
                                }
                            }}
                        />
                        : skillsGroup.skillGroupName
                    }
                    {editModeForGroupName ?
                        <IconButton onClick={handleSaveGroupName} type='button'>
                            <CheckIcon sx={{ color: "gray" }} />
                        </IconButton>
                        :
                        <IconButton onClick={handleEditGroupName}>
                            <ModeEditOutlineIcon sx={{ color: "gray" }} />
                        </IconButton>
                    }
                </Stack>
                <IconButton onClick={handleDeleteSkillGroup}>
                    <DeleteIcon />
                </IconButton>
            </Stack>
            <Stack flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={"1rem"} sx={{ marginBlock: " 10px 1rem" }} flexWrap={"wrap"}>
                {skillsGroup.skills.map((skill, index) => {
                    return <Chip
                        label={skill}
                        key={skill}
                        variant="outlined"
                        onDelete={() => handleDelete(index)}
                        deleteIcon={<DeleteIcon />}
                    />
                })}
            </Stack>
            <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} flexWrap={"wrap"} gap={"1rem"}>
                <TextField
                    label="Skills Required"
                    name='skills'
                    variant='outlined'
                    type='text'
                    inputRef={skillsBoxRef}
                    value={singleSkill}
                    onChange={(e) => setSingleSkill(e.target.value)}
                    onKeyDown={handleAddNewSkill}
                />
                <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"} justifyContent={"center"}>
                    <FormControl fullWidth>
                        <InputLabel id="select-criteria-for-skill-group">Criteria</InputLabel>
                        <Select
                            labelId="select-criteria-for-skill-group"
                            value={skillsGroup.criteria}
                            label="Criteria"
                            onChange={handleGroupDetailsChange}
                            name='criteria'
                        >
                            <MenuItem value={1}>All</MenuItem>
                            <MenuItem value={2}>Atleast one of the mentioned</MenuItem>
                            <MenuItem value={3}>No skill is required</MenuItem>
                        </Select>
                    </FormControl>
                    <Tooltip title="Choose the criteria which will be applied to each skill group, so that the user can select skills based on them">

                        <HelpIcon />
                    </Tooltip>
                </Stack>
            </Stack>
        </Paper>

    )
}

export default SkillGroup
