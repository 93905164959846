import { useState, useEffect, useRef, useContext } from "react";
import ChatGpt from "../ChatGpt";
import CodeEditor from "../components/CodeEditor";
import "./InterviewStyles.css"
import { AppBar, Toolbar, Typography, Box, CssBaseline, Drawer, Divider, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { fetchOpeningById } from "../utilities/openingsApi";
import { fetchInterviewReportById } from "../utilities/interviewReportsApi";
import steps from "../utilities/sheperdSteps";


function Interview() {
    const params = useParams();
    const [openingData, setOpeningData] = useState(null);
    const [interviewReportData, setInterviewReportData] = useState(null);
    const [interviewLoading, setInterviewLoading] = useState(true);

    useEffect(() => {

        async function getOpeningById() {
            if (params.openingId) {
                const openingData = await fetchOpeningById(params.openingId);
                setOpeningData(openingData)
            }
        }
        async function getInterviewReportById() {
            if (params.interviewReportId) {
                const interviewReportData = await fetchInterviewReportById(params.interviewReportId);
                setInterviewReportData(interviewReportData)
            }
        }
        async function getInterviewReady() {
            setInterviewLoading(true)
            await Promise.allSettled([getOpeningById(), getInterviewReportById()]);
            setInterviewLoading(false)
            try {
                /* global Shepherd*/
                if (window.Shepherd) {
                    const tour = new window.Shepherd.Tour({
                        useModalOverlay: true,
                        // exitOnEsc: true,
                        defaultStepOptions: {
                            cancelIcon: {
                                enabled: true
                            },
                            scrollTo: { behavior: 'smooth', block: 'center' },
                            canClickTarget: false,
                        }
                    });
                    tour.addSteps(steps);
                    setTimeout(() => {
                        tour.start()
                    }, 800)
                }
            }
            catch (e) {
                console.log("could not show onboarding");
            }

        }
        getInterviewReady()
    }, [params]);

    useEffect(() => {

    }, [window.Shepherd])

    const editorRef = useRef(null);
    const monacoRef = useRef(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [codeExample, setCodeExample] = useState("")
    const languages = ["javascript", "html", "css", "python", "java", "C++"];
    const [language, setLanguage] = useState("javascript");
    const [isInterviewDone, setIsInterviewDone] = useState(false)
    // useEffect(()=>{
    //     function pasteHandler(e){
    //         e.preventDefault()
    //     }
    //     document.addEventListener("paste",pasteHandler)
    //     return () => {
    //         document.removeEventListener("paste", pasteHandler);
    //     }
    // },[]);

    // useEffect(() => {
    //     // Create the Monaco Editor instance
    //     const editor = monaco.editor.create(editorRef.current, {
    //         value: 'Initial code goes here',
    //       language: 'javascript', // or any other supported language
    //     });

    //     // Add a custom event listener for the 'paste' event
    //     const handlePaste = (e) => {
    //       // Prevent the default paste behavior
    //         e.preventDefault();
    //       // Optionally, you can display a message or take other actions
    //         console.log('Pasting is not allowed in this editor');
    //     };

    //     // Attach the 'paste' event listener to the editor's container
    //     editorRef.current.addEventListener('paste', handlePaste);

    //     // Cleanup: Remove the event listener when the component unmounts
    //     return () => {
    //         editorRef.current.removeEventListener('paste', handlePaste);
    //         editor.dispose(); // Dispose of the Monaco Editor instance
    //     };
    // }, []);

    function handleLanguageChange(e) {
        setLanguage(e.target.value)
    }

    function handleSnackbarClose() {
        setSnackbarOpen(false)
    }

    function handleEditorDidMount(editor, monaco) {
        editorRef.current = editor;
        monacoRef.current = monaco;
    }

    function handleEditorChange(value, event) {
        setCodeExample(editorRef.current.getValue());
    }

    const drawerWidth = "40%";
    return <>
        {/* <ShepherdTour steps={steps} tourOptions={tourOptions}> */}
        <Box sx={{ display: 'flex' }} >
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{ width: `calc(100% - ${drawerWidth})`, ml: `${drawerWidth}`, backgroundColor: "#f7f7f8", zIndex: "999" }}
            >
                <Toolbar sx={{
                    color: "#121212",
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <Typography variant="h5" noWrap >
                        {interviewReportData?.preferredName}
                    </Typography>
                    <Typography variant="h6" noWrap >
                        Title : {openingData && openingData?.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                {/* <ComponentHeading heading={"Interview Panel"}/> */}
                <Divider />
                {interviewLoading ?
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ minHeight: "100vh" }}>
                        <CircularProgress /> Getting Your Interview Ready ...
                    </Box>
                    :
                    <ChatGpt
                        drawerWidth={drawerWidth}
                        codeExample={codeExample}
                        setCodeExample={setCodeExample}
                        openingData={openingData}
                        interviewReportData={interviewReportData}
                        setIsInterviewDone={setIsInterviewDone}
                    />
                }
            </Drawer>
            {openingData?.isCodeEditorRequired && <CodeEditor
                handleEditorDidMount={handleEditorDidMount}
                editorRef={editorRef}
                onChange={handleEditorChange}
                language={language}
                languages={languages}
                handleLanguageChange={handleLanguageChange}
                codeExampleValue={codeExample}
            />}
        </Box>
        {/* </ShepherdTour> */}


    </>
}

export default Interview
