const steps = [{
    id: 'tap-to-speak',
    title: 'Submitting a response',
    text: `Submitting a response to the interviewer is easy!\
        Just click the Tap to speak button and start speaking.\
        When you are done, submit your response.`,
    attachTo: {
        element: '#mic-btn',
        on: 'bottom'
    },
    canClickTarget: false,
    buttons: [
        {
            action() {
                return this.back();
            },
            text: 'Back'
        },
        {
            action() {
                return this.next();
            },
            text: 'Next'
        }
    ],

}, {
    id: 'write-your-code',
    title: 'Using the code editor',
    text: `Use the code editor to give a code example.\
    Once done with your code ,\
        click the Tap to speak button to submit your response.`,
    attachTo: {
        element: '#code-editor-for-candidate',
        on: 'left'
    },
    canClickTarget: false,
    buttons: [
        {
            action() {
                return this.back();
            },
            text: 'Back'
        },
        {
            action() {
                return this.next();
            },
            text: 'Done'
        }
    ],
}]

export default steps;