import React from 'react'
import Typography from '@mui/material/Typography';
import { Box, Toolbar, Tooltip, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from 'react';

const ComponentHeading = ({ heading, info = false, languages, language, handleChange }) => {
    return (
        <Box >
            <Toolbar sx={{
                backgroundColor: "#337CCF",
                color: "#f1f1f1",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
            }}>
                <Typography variant="body1" sx={{
                    display: "flex",

                }}>
                    {heading}
                </Typography>
                {info && <Tooltip title={<TooltipTitle />}>
                    <InfoOutlinedIcon />
                </Tooltip>
                }
                {info && <LanguageOptions languages={languages} language={language} handleChange={handleChange} />}
                <Typography variant='body2' style={{ color: "#d4d4d4", justifySelf: "flex-end", marginLeft: "auto" }}>
                    After you've completed writing the code, use the microphone to explain or submit it
                </Typography>
            </Toolbar>
        </Box>
    );
}

export default ComponentHeading

function LanguageOptions({ languages, language, handleChange }) {

    return <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <label htmlFor='code-editor-select'>Language</label>
        <select
            id="code-editor-select"
            value={language}
            onChange={handleChange}
        >
            {languages.map(language => <option value={language} key={language}>{stringCapitalize(language)}</option>)}
        </select>
    </FormControl>;
}

function stringCapitalize(str) {
    let newStr = str[0].toUpperCase() + str.substring(1).toLowerCase();
    return newStr;
}

function TooltipTitle() {
    // return <span>Submit your code by pressing the <em>Save Code</em> button and explain your code using the microphone or textbox.</span>
    return <div style={{ padding: "8px 14px" }}>
        <strong>Steps to follow : </strong>
        <ol>
            <li>Write the code example you've been asked for in your desired language.</li>
            <li>Explain your code using the microphone and submit.</li>
            <li>You can Undo or Redo your changes in this code editor using <em>Ctrl Z</em> or <em>Ctrl Y</em></li>
        </ol>
    </div>
}