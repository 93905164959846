import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavbarStyles.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {  purple } from '@mui/material/colors'
import { Typography } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: "#f8f8f8"
        },
        secondary: purple,
    },
});

function Navbar(){
    return <ThemeProvider theme={theme}>
        <header className="navbar-header">
            <nav>
                <Typography component={"h1"} variant="h5" className="header-logo" noWrap>
                    <NavLink to={"admin"}>Admin Panel</NavLink>
                </Typography>
                <IconButton color="primary" className="logout-button">
                    <span className="logout-label">Logout</span>
                    <LogoutIcon fontSize="medium" />
                </IconButton>
                <IconButton color="primary">
                    <AccountCircleIcon fontSize="medium" />
                </IconButton>

            </nav>
        </header>
    </ThemeProvider> 
}

export default Navbar;