import React, { useState } from 'react'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Paper, Typography, Chip, Stack, Divider, Snackbar, Button, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { fetchOpeningById } from '../utilities/openingsApi';
import { updateUserSkillsForInterview } from '../utilities/interviewReportsApi';

const InterviewTopicOptions = () => {
    const params = useParams();
    const navigate = useNavigate();

    // stores array of skill groups
    // [{ name: name, criteria, [{ skill, selected }, { skill, selected }] }]
    // each array element is of the above schema
    const [skillsGroup, setSkillsGroup] = useState([])
    const [animate, setAnimate] = useState(false);
    const [openingData, setOpeningData] = useState();
    const [isFetchingOpeningData, setIsFetchingOpeningData] = useState(false);
    const [snackbarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState(false);
    const [isUpdatingSkills, setIsUpdatingSkills] = useState(false)
    const criteriaMap = {
        1: "All skills are required from this group",
        2: "At least one skill is mandatory from this group",
        3: "No skill is mandatory from this group, but they're good to have."
    }
    const errorMap = {
        1: "All the mentioned skills are required",
        2: "Please pick at least one skill from this group",
        3: "No skills from this are required"
    }
    useEffect(() => {
        async function getOpeningData() {
            if (params.openingId) {
                setIsFetchingOpeningData(true)
                const data = await fetchOpeningById(params.openingId);
                if (data) {
                    setOpeningData(data)
                    if (data.skillsGroup && data.skillsGroup.length > 0) {
                        const { skillsGroup } = data;
                        let tempArr = skillsGroup.map((group, i) => {
                            const { skills, skillGroupName, criteria } = group;
                            if (skills && skills.length > 0) {
                                let arrayOfSkillsForSingleGroup = skills.map((skill) => ({ skill, selected: false }));
                                return { skillGroupName, criteria, skills: arrayOfSkillsForSingleGroup, errorMessage: "" };
                            }
                        })
                        console.log("temp arr in useEffect");
                        console.log(tempArr);
                        setSkillsGroup(tempArr)
                    }
                }
                setIsFetchingOpeningData(false);
            }
        }
        getOpeningData();
    }, [params])

    function handleAddSkill(skillIndex, groupIndex) {
        setSkillsGroup(prev => {
            const tempPrevGroup = structuredClone(prev);
            const prevVal = tempPrevGroup[groupIndex].skills[skillIndex].selected
            tempPrevGroup[groupIndex].skills[skillIndex].selected = !prevVal;
            return tempPrevGroup
        })
    }

    // function handleDeleteSkill(skillIndex, groupIndex) {
    //     setSkillsGroup(prev => {
    //         const tempPrevGroup = structuredClone(prev);
    //         tempPrevGroup[groupIndex].skills[skillIndex].selected = false;
    //         return tempPrevGroup
    //     })
    // }

    async function checkCriteria() {
        let errorsCount = 0;
        skillsGroup.forEach((group, groupIndex) => {
            const { skills, criteria, skillGroupName } = group;
            let checks;
            if (criteria == 1) {
                checks = skills && skills.length > 0 && skills.every((skillObj) => skillObj.selected === true);
            }
            if (criteria == 2) {
                checks = skills && skills.length > 0 && skills.some((skillObj) => skillObj.selected === true);
            }
            if (criteria == 3) {
                // no checks are required
                checks = true;
            }
            if (!checks) {
                ++errorsCount;
                setSkillsGroup(prev => {
                    const tempPrevGroup = structuredClone(prev);
                    tempPrevGroup[groupIndex].errorMessage = errorMap[criteria];
                    return tempPrevGroup
                })

                setAnimate(true)
                setTimeout(() => {
                    setAnimate(false)
                }, 500)
            }
            else {
                setSkillsGroup(prev => {
                    const tempPrevGroup = structuredClone(prev);
                    tempPrevGroup[groupIndex].errorMessage = "";
                    return tempPrevGroup
                })
            }

        });
        console.log(errorsCount);
        if (errorsCount == 0) {
            const skills = skillsGroup.map(group => {
                const { skills } = group;
                return skills.filter((skillsObj) => skillsObj.selected).map((skillsObj) => skillsObj.skill)
            })
            setIsUpdatingSkills(true)
            const res = await updateUserSkillsForInterview([].concat(...skills), params.interviewReportId);
            if (res) {
                setIsUpdatingSkills(false)
                navigate(`/interview/${params.openingId}/start/${params.interviewReportId}`)
            }
        }

    }

    function handleSaveUserChoices() {
        checkCriteria()
    }
    const handleSnackBarClose = (e) => {
        setSnackBarOpen(false)
    }


    const StackOfGroups = skillsGroup && skillsGroup.length > 0 && (
        <Stack flexDirection={"column"} gap={"1rem"}>
            {
                skillsGroup.map((group, groupIndex) => {
                    const { skills, criteria, skillGroupName, errorMessage } = group;
                    return <Paper elevation={2} sx={{ bgcolor: "rgba(255,255,255,0.4)", backdropFilter: "blur(10px)", padding: "1rem" }} key={skillGroupName}
                        className={animate && errorMessage ? "error-groups" : ""}>
                        <Stack flexDirection={"column"} gap={"1rem"} justifyContent={"center"} alignItems={"flex-start"} >
                            <Typography variant='h6' component={"h2"}>Group Name: {skillGroupName}</Typography>
                            <Typography variant='body1' component={"h4"}>Criteria : {criteriaMap[criteria]}</Typography>
                            <Stack justifyContent={"center"} alignItems={"center"} flexDirection={"row"} gap={"1rem"}>
                                {errorMessage &&
                                    <Box sx={{ display: "flex", gap: "1rem", padding: "10px", backgroundColor: "rgb(54, 48, 98, 0.4)", borderRadius: "4px" }}>
                                        <ErrorIcon />
                                        <Typography variant='body1' component={"h3"}>{errorMessage}</Typography>
                                    </Box>
                                }
                            </Stack>
                            <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"center"} gap={"1rem"}>

                                {skills.map((skillObj, skillIndex) => {
                                    const { skill, selected } = skillObj;
                                    const colorX = selected ? "primary" : "default";
                                    return <Chip key={skill} label={skill}
                                        color={colorX}
                                        // deleteIcon={null}
                                        // onDelete={() => handleDeleteSkill(skillIndex, groupIndex)}
                                        onClick={() => handleAddSkill(skillIndex, groupIndex)}
                                        sx={{ fontSize: "1rem", padding: "0.625rem", color: "lavender" }}
                                    />
                                }
                                )}
                            </Stack>
                        </Stack>
                    </Paper>
                })
            }
        </Stack>
    )

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackBarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <Box sx={{ backgroundColor: "lavender", minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Paper elevation={4} sx={{
                    bgcolor: "#4F709C", width: "min(100% - 2rem, 710px)", color: "#fff", marginInline: "auto", height: "fit-content", padding: { md: "3rem 2rem", xs: "2rem 10px" }, borderRadius: "5px",
                    display: "flex", flexDirection: "column", gap: "1rem", marginBlock: "3rem"
                }}>
                    <Box sx={{
                        position: "sticky", top: "0", zIndex: "2", backgroundColor: "transparent", width: "100%", backdropFilter: "blur(5px)"
                        , paddingBlock: "1rem"
                    }}>
                        <Typography variant='h4' component={"h1"} textAlign={"center"} >Pick your Battles</Typography>
                        <Typography variant='h6' component={"h5"} textAlign={"center"} >
                            Select skills from each group based on the criteria mentioned and your comfort</Typography>
                    </Box>
                    <Divider sx={{ width: "98%", marginInline: "auto" }} />
                    {isFetchingOpeningData ?
                        <CircularProgress /> :
                        StackOfGroups
                    }
                    <Button
                        onClick={() => {
                            checkCriteria()
                        }}
                        sx={{ alignSelf: "flex-end", marginRight: "1rem", backgroundColor: "rgba(1,1,1,0.4)", padding: "10px" }}
                        endIcon={!isUpdatingSkills && <ArrowRightAltIcon sx={{ color: "white" }} />} variant='contained'>

                        {isUpdatingSkills ? <Stack justifyContent={"center"} alignItems={"center"} flexDirection={"row"} gap={"1rem"}>
                            <CircularProgress sx={{ color: "lavender" }} />
                            <Typography sx={{ color: "lavender" }}>
                                Getting Ready ..
                            </Typography>
                        </Stack> :
                            <Typography sx={{ color: "lavender" }}>
                                LET's GO
                            </Typography>}
                    </Button>
                </Paper>

            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackBarClose}
                message={snackBarMessage}
                action={action}
            />
        </>
    )
}

export default InterviewTopicOptions

// useEffect(() => {
//     setTimeout(() => {
//         navigate(`/interview/${params.openingId}/start/${params.interviewReportId}`)
//     }, 3000)
// }, [])