import React from 'react'
import { Paper,IconButton, Box } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DummyOpenings = () => {

    return (
        <>
        {(Array.from({length:5},(c,i) => i+1)).map(opening => {
            return <React.Fragment key={opening}>
            <Paper  sx={{display:"flex", padding: "1rem 2rem", marginBottom:"1rem", width:"80%", marginInline:"auto",
                        justifyContent:"space-between", alignItems:"flex-start", height:"100px"}}  elevation={4} >
            </Paper>
        </React.Fragment>

        })}
    </>
    )
}

export default DummyOpenings