import { Box, Paper, Typography, CircularProgress, Button } from '@material-ui/core';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom'
import { fetchOpeningById } from '../utilities/openingsApi';
import { fetchInterviewReportsByOpening } from '../utilities/interviewReportsApi';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MessageIcon from '@mui/icons-material/Message';
import styled from '@emotion/styled';
import { getFormattedDateTime } from '../utilities/utilityFunctions';
import { Chip, Stack } from '@mui/material';

const OpeningEvaluations = () => {
    const params = useParams();
    const [openingData, setOpeningData] = useState();
    const [interviewReports, setInterviewReports] = useState([]);
    const [fetchingOpeningData, setFetchingOpeningData] = useState(false);
    const [loadingReports, setLoadingReports] = useState(false);
    useEffect(() => {
        async function getOpeningById() {
            if (params.openingId) {
                setFetchingOpeningData(true)
                const data = await fetchOpeningById(params.openingId);
                console.log(data);
                setOpeningData(data)
                setFetchingOpeningData(false)
            }
        }
        async function getInterviewReportsForOpening() {
            if (params.openingId) {
                setLoadingReports(true)
                const reports = await fetchInterviewReportsByOpening(params.openingId);
                setInterviewReports(reports)
                setLoadingReports(false)
            }
        }
        getOpeningById();
        getInterviewReportsForOpening()
    }, [params.openingId])
    return (
        <Box className='margin-top-for-nav' sx={{ margin: "1rem 2rem", width: "min(100% - 1rem, 1150px)", marginInline: "auto" }}>
            <Paper style={{ padding: "2rem", textAlign: "center" }} elevation={4}>
                {openingData &&
                    <>
                        <h1>Opening Details</h1>
                        <p> <StyledSpan>Title</StyledSpan> : {openingData.title}</p>
                        <p> <StyledSpan>Created On</StyledSpan> : {getFormattedDateTime(openingData.createdAt)}</p>
                    </>}
            </Paper>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} sx={{ padding: "1rem", gap: "1rem", marginBlock: "2rem" }} flexWrap={"wrap"}>
                {
                    (loadingReports || fetchingOpeningData) ? <CircularProgress /> : interviewReports.length > 0 ? interviewReports.map(interviewReport => {
                        return <Paper key={interviewReport._id} style={{ padding: "1rem", backgroundColor: "#f6f6f7", width: "100%" }} elevation={4}>
                            <Typography variant='h6'> Report Id: {interviewReport._id}</Typography>
                            {interviewReport.firstName && <p><StyledSpan>First Name: </StyledSpan>  {interviewReport.firstName}</p>}
                            {interviewReport.lastName && <p><StyledSpan>Last Name: </StyledSpan>  {interviewReport.lastName}</p>}
                            {interviewReport.phoneNumber && <p><StyledSpan>Phone Number: </StyledSpan>  {interviewReport.phoneNumber}</p>}
                            {interviewReport?.preferredSkills &&
                                <Stack flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"center"} gap={"10px"} flexDirection={"row"}>
                                    <StyledSpan>Preferred Skills: </StyledSpan>
                                    <Stack flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"center"} gap={"10px"} flexDirection={"row"}>
                                        {interviewReport.preferredSkills.map(skill => <Chip key={skill} label={skill}
                                            sx={{ backgroundColor: "lavender" }}
                                        />)}
                                    </Stack>
                                </Stack>
                            }
                            <p><StyledSpan> Dated on :</StyledSpan> {getFormattedDateTime(interviewReport.createdAt)}</p>
                            <Stack sx={{ marginBlock: "1rem" }} display={"flex"} gap={"1rem"} flexDirection={"row"}>
                                <Button component={Link} to={`/riktam/evaluation/${interviewReport._id}?openingId=${openingData._id}`}
                                    variant='text' color='primary' startIcon={<AssessmentIcon />}>
                                    Get Evaluation
                                </Button>
                                <Button component={Link} to={`/riktam/messages/${interviewReport._id}?openingId=${openingData._id}`}
                                    variant='text' color='secondary' startIcon={<MessageIcon />}>
                                    Show conversation
                                </Button>
                            </Stack>
                        </Paper>
                    }) : <Typography>No Reports Found</Typography>
                }
            </Box>
        </Box>
    )
}

export default OpeningEvaluations

const StyledSpan = styled.span`
    font-weight: 500;
`;

