import React from 'react'
import Navbar from './Navbar'
import { Outlet } from 'react-router-dom'

const BaseAdminNavPage = () => {
    return (<>
        <Navbar />
        <Outlet />
    </>
    )
}

export default BaseAdminNavPage