import React from 'react'
import { Box, Typography, Stack, Skeleton } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { fetchInterviewReportById } from '../utilities/interviewReportsApi'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monoBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const InterviewMessages = () => {
    const params = useParams();
    const [searchParams] = useSearchParams()
    const [messages, setMessages] = useState(null);
    const [isLoadingMessages, setIsLoadingMessages] = useState(false);
    const [userName, setUserName] = useState("")
    useEffect(() => {

        async function getMessages() {
            const openingId = searchParams.get("openingId");
            if (params.interviewReportId && openingId) {
                setIsLoadingMessages(true)
                const report = await fetchInterviewReportById(params.interviewReportId)
                if (report.messages) {
                    setMessages(report.messages)
                }
                if (report.preferredName) {
                    setUserName(report.preferredName)
                }
                setIsLoadingMessages(false)
            }
        }
        getMessages()
    }, [params, searchParams]);

    const renderMessages = !isLoadingMessages && messages && messages.length > 0 && messages.map((message, i) => {
        const { role, content } = message;
        return <MessageBox message={content} user={role} key={i} userName={userName} />
    })
    return (
        <Box className="margin-top-for-nav" sx={{ textAlign: "center", paddingBottom: "1rem", display: "flex", alignItems: "center", justifyContent: "center", minHeight: "100vh", flexDirection: "column" }}>
            <Typography variant='h3' component={"h1"} gutterBottom>Interview Exchange</Typography>
            <Box sx={{
                backgroundColor: "#fafafa", flex: 1, width: "min(100% - 1rem , 1020px)", padding: "0.625rem 0.5rem",
                display: "flex", flexDirection: "column", gap: "1rem"
            }}>
                {isLoadingMessages ? <DummyMessages /> : renderMessages}
            </Box>
        </Box>
    )
}

export default InterviewMessages

const MessageBox = ({ user, message, userName }) => {
    const tankLeft = user === "assistant";
    const codeStringIndex = message.indexOf("Example: <");
    const codeString = codeStringIndex !== -1 ? message.substring(codeStringIndex + 10, message.length - 1) : "";
    const responseMessage = codeStringIndex == -1 ? message : message.substring(0, codeStringIndex - 1);
    return <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} alignItems={tankLeft ? "flex-start" : "flex-end"}
    >
        <Typography variant='body2'>{user == "assistant" ? "Interviewer" : userName ? userName : "Candidate"}</Typography>
        <Box sx={{
            backgroundColor: "lavender", borderRadius: "4px", padding: "1rem", width: "fit-content", maxWidth: { xs: "100%", sm: "75%" },
            textAlign: "left"
        }}>
            <Typography variant='body1'>{responseMessage}</Typography>
            {
                codeString && <Box margin={"1rem"}>
                    {(codeStringIndex != -1 && codeString.length > 0) && <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", backgroundColor: "black", borderRadius: "10px 10px 0 0", paddingInline: "10px" }}>
                            <span style={{ color: "white", padding: "5px 3px" }}>Example</span>
                        </div>
                        <SyntaxHighlighter language={"javascript"} style={monoBlue} customStyle={{ padding: "2rem", borderRadius: "0 0 10px 10px" }}
                        >
                            {codeString}
                        </SyntaxHighlighter>
                    </div>}
                </Box>
            }
        </Box>
    </Box>
}

function DummyMessages() {
    const arr = Array.from({ length: 10 }, (_, i) => i);

    return <Stack flexDirection={"column"} gap="2rem" >
        {arr.map(i => {
            const tankLeft = i % 2 == 0;
            return <Box key={i} display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} alignItems={tankLeft ? "flex-start" : "flex-end"} gap={"3px"}>
                <Skeleton variant="rectangular" width={"100px"} height={20} sx={{ borderRadius: "10px" }} />
                <Skeleton variant="rectangular" width={"80%"} height={50} sx={{ borderRadius: "10px" }} />
            </Box>
        })}
    </Stack>
}