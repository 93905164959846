import React, { useState } from 'react'
import { TextField, Grid, Stack, Paper, Box, Typography, Button, CircularProgress } from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import { saveUserDetailsForAnInterivew } from '../utilities/interviewReportsApi';
const initialUserDetails = {
    firstName: "",
    lastName: "",
    preferredName: "",
    phoneNumber: ""
}

const UserDetails = () => {
    const params = useParams();
    const [userDetails, setUserDetails] = useState(initialUserDetails);
    const [snackbarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [savingUserDeets, setSavingUserDeets] = useState(false)
    const navigate = useNavigate()
    const handleUserDetailsChange = (e) => {
        const { name, value } = e.target;
        setUserDetails(prev => {
            return { ...prev, [name]: value };
        })
    }

    const handleUserDetailsSubmit = async (e) => {
        e.preventDefault();
        if (userDetails.firstName.trim() !== "" && userDetails.lastName.trim() !== ""
            && userDetails.preferredName.trim() !== "" && userDetails.phoneNumber.trim() !== "") {
            console.log(userDetails);
            if (params.openingId) {
                setSavingUserDeets(true)
                const res = await saveUserDetailsForAnInterivew(userDetails, params.openingId);
                const { message, data } = res;
                setSnackBarMessage(message ? message : "User Details saved successfully");
                setSnackBarOpen(true)
                setSavingUserDeets(false);
                setSavingUserDeets(initialUserDetails)
                if (data._id) {
                    // data._id is the interviewReportId
                    // navigate(`start/${data._id}`)
                    navigate(`topics/${data._id}`, { replace: true })
                }
            }
        }
        else {
            setSnackBarMessage("Few Details are missing!")
            setSnackBarOpen(true);
        }
    }

    const handleSnackBarClose = (e) => {
        setSnackBarOpen(false)
    }

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackBarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <Box sx={{ backgroundColor: "lavender", minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Paper elevation={4} sx={{ margin: "2rem", width: "min(100% - 2rem, 600px)", marginInline: "auto", height: "fit-content", padding: { md: "3rem 2rem", xs: "2rem 0" }, borderRadius: "5px" }}>
                    <Typography variant='h4' component={"h1"} textAlign={"center"} gutterBottom>Let's get to know you</Typography>
                    <Box sx={{ marginTop: "1.625rem" }}>
                        <form onSubmit={handleUserDetailsSubmit}>
                            <Grid container spacing={4} justifyContent={"center"} alignItems={"center"} direction="row" >
                                <Grid item xs={10}>
                                    <Stack direction={"row"} justifyContent={"center"} gap={"1rem"} flexWrap={{ xs: "wrap", sm: "nowrap" }}>
                                        <Grid item xs={12} >
                                            <TextField id="candidate-first-name" label="First Name" name="firstName" variant="outlined" required type="text" fullWidth
                                                value={userDetails.firstName}
                                                onChange={handleUserDetailsChange}
                                                disabled={savingUserDeets}
                                                autoFocus={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} >
                                            <TextField id="candidate-last-name" label="Last Name" name="lastName" variant="outlined" required type="text" fullWidth
                                                value={userDetails.lastName}
                                                onChange={handleUserDetailsChange}
                                                disabled={savingUserDeets}
                                            />
                                        </Grid>
                                    </Stack>
                                </Grid>
                                <Grid item xs={10} >
                                    <TextField id="candidate-preferred-name" label="Preferred Name" name="preferredName" variant="outlined" required type="text" fullWidth
                                        value={userDetails.preferredName}
                                        onChange={handleUserDetailsChange}
                                        disabled={savingUserDeets}
                                    />
                                </Grid>
                                <Grid item xs={10} >
                                    <TextField id="candidate-phone-number" label="Phone Number" name="phoneNumber" variant="outlined" required type="tel" fullWidth
                                        value={userDetails.phoneNumber}
                                        onChange={handleUserDetailsChange}
                                        disabled={savingUserDeets}
                                    />
                                </Grid>
                                <Grid item xs={8} >
                                    <Button variant="contained" id="submit-user-details" type='submit' fullWidth size='large' sx={{ backgroundColor: "black" }}>
                                        {savingUserDeets ? <>
                                            <CircularProgress color='secondary' />
                                            Saving Details...
                                        </>
                                            : "Submit"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Paper>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackBarClose}
                message={snackBarMessage}
                action={action}
            />
        </>
    )
}

export default UserDetails