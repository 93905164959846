import React from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { Box, CssBaseline } from '@mui/material'
const InterviewHomePage = () => {
    return (<>
        {/* <CssBaseline /> */}
        <Outlet />
    </>
    )
}

export default InterviewHomePage