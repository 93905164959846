import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "./AdminPanelStyles.css";
import { Button, Box, IconButton, Paper, Typography, Tooltip, Accordion, AccordionSummary, AccordionDetails, Stack, Chip } from '@mui/material';
import { Outlet, useNavigate, Link } from "react-router-dom";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fetchOpenings, deleteOpeningById, updateOpeningById } from "../utilities/openingsApi";
import DummyOpenings from "../components/DummyOpenings";
import styled from "@emotion/styled";

const BASE_URL = "http://localhost:3000"

function AdminPanel() {
    const navigate = useNavigate();
    const [openings, setOpenings] = useState([]);
    const [isFetchingOpenings, setIsFetchingOpenings] = useState(false);

    async function getOpenings() {
        setIsFetchingOpenings(true)
        const data = await fetchOpenings();
        if (data && Array.isArray(data)) {
            setOpenings(data);
        }
        setIsFetchingOpenings(false);
    }

    useEffect(() => {
        getOpenings();
    }, [])

    const handleCreateOpening = () => {
        navigate("/riktam/create-opening");
    }

    async function handleDeleteInterviewBtnClick(openingId) {
        const response = await deleteOpeningById(openingId);
        console.log(response);
        getOpenings();

    }

    async function handleUpdateInterviewBtnClick(openingId) {
        navigate(`/riktam/create-opening/${openingId}`)
    }

    async function handleCopyInterviewLink(openingId) {
        const interviewLink = `/interview/${openingId}`
        await navigator.clipboard.writeText(interviewLink);
    }

    return <>
        <Box className="admin-panel-container margin-top-for-nav" sx={{ backgroundColor: "#f2f2f2" }} component={"main"}>
            <Button variant="contained" onClick={handleCreateOpening} sx={{ marginInline: "auto", display: "block" }} >
                Create an Opening</Button>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} sx={{ padding: "2rem", gap: "1rem" }}>
                <Typography variant="h3" component={"h1"} gutterBottom>Openings</Typography>
                {isFetchingOpenings ? <DummyOpenings /> : [...openings].reverse().map(opening => {
                    return <React.Fragment key={opening._id}>
                        <Paper sx={{
                            display: "flex", padding: "1rem 2rem", width: "80%",
                            justifyContent: "space-between", alignItems: "flex-start"
                        }} elevation={4} >
                            <div style={{ display: "flex", flexDirection: "column", gap: "0.45rem", alignItems: "flex-start", justifyContent: "center", flex: 3 }}>
                                <h2>Job Title : {opening.title}</h2>
                                <p><StyledSpan>Description</StyledSpan> : {opening.description}</p>
                                <p><StyledSpan>Skills</StyledSpan> : {opening.skills}</p>
                                <Accordion sx={{ backgroundColor: "lavender", width: "fit-content" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Skills</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Stack gap={"0.8rem"}>
                                            {opening?.skillsGroup && opening?.skillsGroup.length > 0 &&
                                                opening.skillsGroup.map((group, index) => {
                                                    const { criteria, skillGroupName, skills } = group;
                                                    return <Stack justifyContent={"center"} alignItems={"flex-start"} gap={"0.625rem"} key={skillGroupName + index}>
                                                        <Typography variant="h6" component={"h3"}>
                                                            {skillGroupName}
                                                        </Typography>
                                                        <Stack justifyContent={"flex-start"} flexDirection={"row"} gap={"1rem"} flexWrap={"wrap"}>
                                                            {skills.map((skill) => {
                                                                return <Chip key={skill} label={skill}
                                                                />
                                                            }
                                                            )}
                                                        </Stack>
                                                    </Stack>
                                                })}
                                        </Stack>
                                    </AccordionDetails>
                                </Accordion>
                                <p><StyledSpan>Experience Required</StyledSpan> : {opening.experience}</p>
                                <p><StyledSpan>AI asks questions based on Resume</StyledSpan> : {opening.questionsBasedOnResume ? "Yes" : "No"}</p>
                                <p><StyledSpan> Interview Link : </StyledSpan>
                                    <Button component={Link} to={`/interview/${opening._id}`} target="_blank" > Interview </Button>
                                </p>
                                <p> <StyledSpan>Interviews and Evaluations : </StyledSpan>
                                    <Button component={Link} to={`/riktam/evaluations/${opening._id}`} color="secondary"> Reports </Button>
                                </p>
                            </div>
                            <div style={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center", flexWrap: "wrap" }}>
                                <IconButton onClick={() => handleCopyInterviewLink(opening._id)}>
                                    <ContentCopyIcon />
                                </IconButton>
                                <Tooltip title="This feature is not available yet">
                                    <IconButton onClick={() => {
                                        // disabled for now because updating state is not yet done
                                        return;
                                        handleUpdateInterviewBtnClick(opening._id)
                                    }
                                    } >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <IconButton onClick={() => handleDeleteInterviewBtnClick(opening._id)}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </div>
                        </Paper>
                    </React.Fragment>
                })
                }
            </Box>
        </Box>
    </>;
}

export default AdminPanel;

const StyledSpan = styled.span`
    font-weight: 500;
`;