import axios from "axios";

async function fetchInterviewReportsByOpening(openingId) {
    try {
        const response = await axios.get(`/api/interviewReports/${openingId}`);
        console.log(typeof response.status)
        if (response.status === 200) {
            return response.data.reports;
        }
        else {
            return [];
        }
    }
    catch (e) {
        console.log("something went wrong while fetching interview reports for the opening");
        console.log(e.message)
    }
}

async function fetchInterviewReportById(interviewReportId) {
    try {
        const response = await axios.get(`/api/interviewReports/interviewReport/${interviewReportId}`);
        if (response.status == 200) {
            return response.data.report;
        } else {
            return [];
        }
    }
    catch (e) {
        console.log("error in fetching evaluation report for the opening")
        console.log(e.message)
    }
}

async function getEvaluationForInterivew(openingId, interviewReportId) {
    try {
        console.log("Data")
        console.log(openingId)
        console.log(interviewReportId)
        const response = await axios.post(`/api/interviewReports/interviewReport/evaluation`, { interviewReportId: interviewReportId, openingId: openingId });
        if (!response) {
            return [];
        }
        return response.data.evaluation;
    }
    catch (e) {
        console.log("error in fetching evaluation report for the opening")
        console.log(e.message)
    }
}

async function pollInterviewResultsById(interviewReportId) {
    try {
        const response = await axios.post(`/api/interviewReports/evaluationStatus`, { interviewReportId: interviewReportId });
        console.log(response)
        if (response.status === 200) {
            return response.data;
        }
        else {
            return "Something went wrong"
        }
    }
    catch (e) {
        console.log("error in fetching evaluation report for the opening")
        console.log(e.message)
    }
}

async function saveUserDetailsForAnInterivew(userDetails, openingId) {
    try {
        const response = await axios.post(`/api/interviewReports/createUser`, { userDetails, openingId });
        console.log(response)
        if (response.status === 201) {
            return response?.data;
        }
        else {
            return "Something went wrong"
        }
    } catch (e) {
        console.log("error in saving user details ");
        console.log(e.message)
    }
}

async function updateUserSkillsForInterview(preferredSkills, interviewReportId) {
    try {
        const response = await axios.post(`/api/interviewReports/updateUserSkills`, { preferredSkills, interviewReportId });
        console.log(response)
        if (response.status === 200) {
            return response?.data;
        }
        else {
            return false;
        }
    } catch (e) {
        console.log("error in saving user details ");
        console.log(e.message)
    }
}




export { fetchInterviewReportsByOpening, updateUserSkillsForInterview, fetchInterviewReportById, getEvaluationForInterivew, pollInterviewResultsById, saveUserDetailsForAnInterivew }