import React, { useEffect, useState } from 'react'
import { Box, Paper, TextField, Button, Radio, RadioGroup, InputLabel, FormLabel, FormControlLabel, Grid, Typography, Stack, Chip } from '@mui/material'
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import { useNavigate, useParams } from 'react-router-dom';
import { createOpening, fetchOpeningById, updateOpeningById } from '../utilities/openingsApi';
import SkillGroup from '../components/SkillGroup';

const initialInterviewDetails = {
    title: "",
    description: "",
    experience: 0,
    skillsGroup: "",
    isCodeEditorRequired: "yes",
    questionsBasedOnResume: "yes"
}
function CreateOpening() {

    const navigate = useNavigate();
    const [interviewDetails, setInterviewDetails] = useState(initialInterviewDetails);
    const [showInputError, setShowInputError] = useState(false);
    const [updateOpening, setUpdateOpening] = useState(false);
    const [isCreatingUpdating, setIsCreatingUpdating] = useState(false);
    const [skillsGroup, setSkillsGroup] = useState([])

    const { openingId } = useParams();
    async function fetchOpeningDetailsById(openingId) {
        if (openingId) {
            setUpdateOpening(true);
            const response = await fetchOpeningById(openingId);
            const { title, description, experience, skillsGroup, isCodeEditorRequired, questionsBasedOnResume } = response;
            setInterviewDetails({
                title,
                description,
                experience,
                skillsGroup,
                isCodeEditorRequired: isCodeEditorRequired ? "yes" : "no",
                questionsBasedOnResume: questionsBasedOnResume ? "yes" : "no"
            });
        }
    }
    useEffect(() => {
        fetchOpeningDetailsById(openingId)
    }, [openingId])

    const handleInterviewDetailsChange = (e) => {
        const { name, value } = e.target;
        setInterviewDetails(prev => {
            return { ...prev, [name]: value };
        })
    }


    async function handleCreateInterview(e) {
        e.preventDefault();
        const finalDetails = { ...interviewDetails, skillsGroup: skillsGroup }
        setIsCreatingUpdating(true)
        if (updateOpening) {
            await updateOpeningById(openingId, interviewDetails)
        }
        else {
            await createOpening(finalDetails);
        }
        setIsCreatingUpdating(false)
        navigate("/riktam/admin");
    }

    let buttonText = "";
    if (isCreatingUpdating) {
        buttonText = updateOpening ? "UPDATING" : "CREATING";
    }
    else {
        buttonText = updateOpening ? "UPDATE" : "CREATE";
    }
    const handleSkillGroupChange = (data, index) => {
        setSkillsGroup((prevSkillsGroups) => {
            const updatedSkillsGroups = [...prevSkillsGroups];
            updatedSkillsGroups[index] = data;
            return updatedSkillsGroups;
        });
    }

    const handleDeleteSkillGroup = (index) => {
        setSkillsGroup(prev => {
            return prev.filter((skillGroup, i) => i !== index);
        })
    }
    return (
        <Box className="margin-top-for-nav" sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "1rem 2rem", width: "min(100% - 1rem, 800px)", marginInline: "auto" }}>
            <Paper sx={{ backgroundColor: "rgb(255,255,255,0.4)", padding: "1.86rem 1rem" }} elevation={4}  >

                <Typography variant='h4' component={"h1"} align='center' gutterBottom noWrap className='create-interview-heading'>{updateOpening ? "Update" : "Create"} an Opening</Typography>

                <form onSubmit={handleCreateInterview} autoComplete='off'>
                    <Grid container spacing={2} justifyContent={"center"} alignItems={"center"} direction="row" >
                        <Grid item xs={10} >
                            <TextField id="job-title" label="Job Title" name="title" variant="outlined" required type="text" fullWidth
                                value={interviewDetails.title}
                                onChange={handleInterviewDetailsChange}
                                error={showInputError}
                                autoFocus={true}
                                helperText={showInputError && "Job Title Cannot be empty"}
                            />
                        </Grid>
                        <Grid item xs={10} >
                            <TextField id="job-description" label="Job Description" name="description" variant="outlined" type="text" fullWidth
                                multiline={true}
                                minRows={1}
                                maxRows={4}
                                value={interviewDetails.description}
                                onChange={handleInterviewDetailsChange}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField id="experience" label="Required Experience" name="experience" variant="outlined" required type="number" fullWidth helperText="Minimum experience required in years"
                                value={interviewDetails.experience}
                                onChange={handleInterviewDetailsChange}
                            />
                            {/* <Stack direction={"row"} gap={"1rem"}>
                                <Grid item xs={10}>
                                    <TextField id="min-experience" label="Min Experience" name="minExperience" variant="outlined" required type="number" fullWidth helperText="Minimum experience required in years"
                                        value={interviewDetails.experience.minExperience}
                                        onChange={handleInterviewDetailsChange}
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField id="max-experience" label="Max Experience" name="maxExperience" variant="outlined" required type="number" fullWidth helperText="Maximum experience required in years"
                                        value={interviewDetails.experience.maxExperience}
                                        onChange={handleInterviewDetailsChange}
                                    />
                                </Grid>
                            </Stack> */}
                        </Grid>
                        <Grid item xs={10} display={"flex"} flexDirection={"column"} gap={"1rem"}>
                            {skillsGroup.map((g, i) => {
                                return <SkillGroup key={i} onChange={(data) => handleSkillGroupChange(data, i)} handleDeleteSkillGroup={() => handleDeleteSkillGroup(i)} />
                            })}
                            <Button startIcon={<AddIcon />} onClick={() => setSkillsGroup(prev => [...prev, {}])} sx={{ width: "fit-content" }}>Add new skill Group</Button>
                        </Grid>
                        <Grid item xs={10}>
                            <FormLabel id="is-code-editor-required">Is a Code Editor Required ?</FormLabel>
                            <RadioGroup
                                aria-labelledby="is-code-editor-required"
                                defaultValue="yes"
                                name="isCodeEditorRequired"
                                row
                                value={interviewDetails.isCodeEditorRequired}
                                onChange={handleInterviewDetailsChange}
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={10}>
                            <FormLabel id="ask-questions-based-on-cv">Should the interview be based on the candidates Resume ?</FormLabel>
                            <RadioGroup
                                aria-labelledby="ask-questions-based-on-cv"
                                defaultValue="yes"
                                name="questionsBasedOnResume"
                                row
                                value={interviewDetails.questionsBasedOnResume}
                                onChange={handleInterviewDetailsChange}
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={9} >
                            <Button variant="contained" id="create-interview-btn" type='submit' fullWidth size='large' sx={{ backgroundColor: "black" }}>
                                {buttonText}
                            </Button>
                        </Grid>
                    </Grid>

                </form>
            </Paper>
        </Box>
    )
}

export default CreateOpening